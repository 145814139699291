.SlideIn-appear {
  transform: translateX(30px);
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -o-transform: translateX(30px);
  -ms-transform: translateX(30px);
  opacity: 1;
  &.SlideIn-appear-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all ease-in-out 0.2s;
  }
}

.SlideIn-enter {
  opacity: 1;
  transform: translateX(30px);
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -o-transform: translateX(30px);
  -ms-transform: translateX(30px);
  &.SlideIn-enter-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all ease-in-out 0.2s;
  }
}

.SlideIn-leave {
  opacity: 1;
  width: 100%;
  transform: translateX(-30px);
  -webkit-transform: translateX(-30px);
  -moz-transform: translateX(-30px);
  -o-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  transition: all ease-in-out 0.2s;
  &.SlideIn-leave-active {
    opacity: 1;
    width: 100%;
    transform: translateX(-30px);
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    transition: all ease-in-out 0.2s;
  }
}

.SlideOut-appear {
  transform: translateX(-30px);
  -webkit-transform: translateX(-30px);
  -moz-transform: translateX(-30px);
  -o-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  opacity: 1;
  &.SlideOut-appear-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all ease-in-out 0.2s;
  }
}

.SlideOut-enter {
  opacity: 1;
  transform: translateX(-30px);
  -webkit-transform: translateX(-30px);
  -moz-transform: translateX(-30px);
  -o-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  &.SlideOut-enter-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all ease-in-out 0.2s;
  }
}

.SlideOut-leave {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  &.SlideOut-leave-active {
    opacity: 1;
    position: absolute;
    width: 100%;
    transform: translateX(30px);
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -o-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transition: all ease-in-out 0.2s;
  }
}
