circle.pie {
  fill: #fff;
  stroke: #9E9E9E;
  stroke-width: 25; /* Set the stroke width to half of the circle's radius */
  stroke-dasharray: 0 79; /* Adjusted for the smaller circle */
  animation: fill 3s linear infinite;
}

@keyframes fill {
  0% {
    stroke-dasharray: 0 79;
  }
  100% {
    stroke-dasharray: 79 0;
  }
}

svg.chart {
  margin: 0 auto;
  transform: rotate(-90deg);
  background: #ddd;
  border-radius: 50%;
  display: block;
}