body small, body text, body label, body a, body div, body p,
body h1, body h2, body h3, body h4, body h5, body h6, 
html, html form, .text-uppercase, .cstm-sgmntdd span,
.badge-label span, .fade-text, .insufficient-result-span,
.tag-title, .custom-question, .open-text-acc, .a-page-subtitle
.in-active-badge, .p-title, .a-dropdown-item span, .a-ratings-value span, .filter-label,
table, th, td, .a-page-number span, .group-number, .port-title,
.CaptionCont span{
  font-family: "PwC Helvetica Neue" !important;
}

.container {
  width: 100%;
  background-color: #EEE;
}
.pwcsidebar-enter {
  transform: translateX(100%)
}

.pwcsidebar-enter.pwcsidebar-enter-active {
  transform: translateX(0);
  transition: all 250ms ease-in;
} 

.pwcsidebar-leave {
  transform: translateX(0);
}

.pwcsidebar-leave.pwcsidebar-leave-active {
  transform: translateX(100%);
  transition: all 250ms ease-in;
}