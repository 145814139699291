.user-csv-modal {

    .a-modal-header {
        margin: 15px 0px;
    }

    .csv-downloader {
        color: #eb590b;
        cursor: pointer;
    }

    .microsoft-block {
        color: #909090;
        font-size: 13px;
    }

    .microsoft-error {
        color: #eb590b;
        font-size: 12px;
    }

    .preview-list {
        margin: 20px 0px 10px 0px;

        .preview-item:not(:last-child) {
            border-bottom: 1px solid #e5e5e5;
        }
        
        .preview-item {
            width: 100%;
            display: flex;
            padding: 8px 0px;
    
            .key,
            .value {
                width: 50%;
            }
        }
    }

    .error-detect-footer {
        display: flex;
        align-items: center;
        margin: 20px 0px 0px 0px;

        svg {
            margin-right: 5px
        }
    }

    .a-modal-footer.a-border-tp {
        border: none !important; 
    }

    .note {
        margin: 20px 0px;
        font-size: 13px;
    }

}
    .modal-backdrop {
    z-index: 1 !important;
 }