.dropdown-select-ul {
    left: 0 !important;
}
.custom-select-skelton-dropdown {
    margin-top: 8px !important;
}
.entypo-down-open-big span {
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
}

.skeltons-li .skelton-1{
    margin-right: 8px;
}
.skeltons-li .skelton-1::before{
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right, #d9d9d9 0%, rgba(0,0,0,0.07) 20%, #d9d9d9 40%, #d9d9d9 100%);
    background-repeat: no-repeat;
    background-size: 450px 400px;
    animation: shimmer 0.50s linear infinite;
}
.cstm-sgmntdd span.skelton-bg {
    margin-left: 0;
}

.skelton-bg::before{
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right, #d9d9d9 0%, rgba(0,0,0,0.07) 20%, #d9d9d9 40%, #d9d9d9 100%);
    background-repeat: no-repeat;
    background-size: 450px 400px;
    animation: shimmer 0.50s linear infinite;
}
.skeltons-li .skelton-2::before{
    animation-delay: 0.22s;
}
@keyframes shimmer {
    0%{
        background-position: -450px 0;
    }
    100%{
        background-position: 450px 0;
    }
}