/* Code By Webdevtrick ( https://webdevtrick.com ) */

*, *:before, *:after {
    box-sizing: border-box;
}

h2 {
    color: #34495e;
    padding-bottom: 1.4rem;
    font-size: 3.5rem;
    line-height: 1.2;
}

.container {
    width: 50%;
    max-width: 40rem;
    margin: 4.9rem auto 0;
}

.custom-select {
    display: none;
}

.dropdown-container {
    position: relative;
    width: 200px;
}

/* entypo */

[class*="entypo-"]:before {
    font-family: 'entypo', sans-serif;
}

.dropdown-select {
    z-index: 200;
    padding: 1.4rem;
    text-align: left;
    font-size: 1.8rem;
    line-height: 1;
    cursor: pointer;
    transition: background-color 0.2s ease;
    display: block;
}

.dropdown-select span {
    display: flex;
    align-items: center;
}

.badge-dropdown:after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    z-index: 999;
    right: 10px;
    border: 2px solid #7d7d7c;
    border-width: 0 0 1px 1px;
    transform: rotate( -45deg);
    top: 50%;
    margin-top: -6px;
}

.dropdown-select-ul {
    display: none;
    z-index: 100;
    position: absolute;
    width: 100%;
    height: auto !important;
    min-height: 0px !important;
    max-height: 50rem;
    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: left;
    margin-top: 0px !important;
    border-top: none;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    top: 38px;
}

.dropdown-select-ul li {
    display: block;
    padding: 7px 10px;
    cursor: pointer;
    font-weight: bold;
    color: #666;
    font-size: 16px;
    border-bottom: 1px solid #d8d8d8;
    line-height: 0px;
}

.dropdown-select-ul li.selected {
    color: #666 !important;
}

.dropdown-select-ul li div:first-child {
    line-height: 16px;
}

.dropdown-select-ul .__react_component_tooltip {
    max-width: 250px;
}

.dropdown-select-ul li.optgroup {
    width: 92%;
    padding-right: 0.7rem;
    margin: 0 4%;
    border-bottom: 1px solid;
    font-size: 90%;
    text-align: right;
}

.active .dropdown-select-ul {
    display: block !important;
    animation-fill-mode: both;
    animation-duration: 0.3s;
    animation-name: fadeIn;
}

.cstm-slct-drp-dwn .active {
    border: 2px solid #eb590b;
}

.no-js .custom-select {
    display: none;
}

.no-js .dropdown-select-ul {
    display: none;
}

.dropdown-select {
    color: #ecf0f1;
    height: 2.125rem;
    display: flex;
    border-radius: 0.125rem;
    background-color: var(--theme-color-input);
    color: var(--theme-color-font);
    padding: 0 0.625rem;
    font-size: 0.875rem;
    align-items: center;
    line-height: 2.125rem;
}

.dropdown-select-ul {
    border-color: #34495e;
    background: #fff;
    color: #34495e;
}

.dropdown-select-ul li:hover, .dropdown-select-ul li:focus {
    background: #e8e8e8;
}

.dropdown-select-ul li.selected {
    color: #34495e;
}

.dropdown-select-ul li.optgroup {
    color: #a1aab0;
    border-bottom-color: #bdc3c7;
}

.circle-badge {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    margin-left: 2px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.circle-badge.green {
    background: green
}

.circle-badge.yellow {
    background: yellow
}

.circle-badge.red {
    background: red
}

.dd-header span, .dd-footer small {
    font-size: 12px;
    color: #cccccc;
    line-height: 12px;
    display: block;
    margin: 5px 0;
}

.badge-dropdown {
    width: 100%;
    max-width: 300px;
}
.badge-label,
.segment_overlay {
  position: absolute;
  font-weight: bold;
  top: -2px;
  right: 6px;
  bottom: 0px;
}

.segment_overlay {
  display: none;
  color: rgb(36, 35, 35);
  height: 20px;
  width: 24px;
  left: 215px;
  top: 3px;
  z-index: 91;
}
.tollip-show {
    width: "100px";
    overflow-wrap: break-word !important;
    display: block !important;
    white-space: break-spaces;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
}


.dropdown-item-c:hover div.segment_overlay {
  display: inherit;
}
.segment_overlay button:focus {
  outline: unset;
}
.badge-dropdown .dropdown-item-c>div {
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
}

.dd-body>div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dd-body {
    max-height: 253px;
    overflow: auto;
}

.badge-dropdown .dropdown-item-c span {
    font-size: 11px;
    color: #aaa;
}

.badge-dropdown .dropdown-select {
    width: 250px;
}

.fade-text {
    padding-left: 10px;
}

/* .invisible {
    opacity: 0;
} */

.entypo-down-open-big span {
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
}

.cstm-sgmntdd {
    display: flex;
    align-items: center;
}

.cstm-sgmntdd i {
    position: absolute;
}

.cstm-sgmntdd span {
    margin-left: 22px;
    overflow: hidden;
    width: 190px;
    display: block !important;
    color: #2d2d2d !important;
}

.cstm-slct-drp-dwn .dropdown-select i.arrow-badge {
    position: absolute;
    right: 0.625rem;
    -webkit-transform: rotate( -45deg);
    transform: rotate( -45deg);
    color: #9e9e9e;
    font-size: 0.75rem;
    height: auto;
    bottom: auto;
    background-image: none;
    border-bottom: 2px solid;
    border-left: 2px solid;
    width: 7px;
    height: 7px;
}

.cstm-slct-drp-dwn .active .dropdown-select i.arrow-badge {
    -webkit-transform: rotate( 135deg);
    transform: rotate( 135deg);
}

.cstm-slct-drp-dwn .dropdown-select-ul li .badge-label span {
    font-size: 17px;
}