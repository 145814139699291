a:focus,a:hover,a:active{
    text-decoration: none;
    outline: none;
}
/*header css */
#app-container .a-header {
    position: fixed;
    height: 5.125rem;
    box-shadow: none;
    z-index: 10;
    top:0;
    border-bottom: 1px solid #979797;
    .a-pwc-logo-grid {
   
        background-size: contain;
      
        width: 65px;
        height: 55px;
    }
    .app-title {
        font-size: 20px;
    }
    .a-logout .user-name.size-24 {
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        font-size: 1.2rem;
        &.a-bg-primary {
            background-color: #EB590B !important;
        }
    }
    .link-icon {
        width: auto;
        height: auto;
        display: flex;
        line-height: 0;
        text-align: center;
        margin-right: 0.625rem;
        cursor: pointer;
        .appkiticon {
            font-size: 34px;
            line-height: 34px;
            color: #7D7D7D;
        }
    }
}
/*logout dropdown*/
.a-logout{
    position: relative;
    .dropdown-menu{
        max-width: 200px;
        padding: 10px 0 ;
        .user-infor-list{
            border:none;
            .user-infor-list-item{
                padding: 10px; 
                a:focus{
                    outline:none;
                    text-decoration: none;
                }
            }
            .user-infor-list-item:hover{
                font-weight: normal;
                background: #eee;
            }
        }
    }
}
.navigation-icon-left{
    font-size: 26px;
}
.h-settings-button.a-btn.a-btn-secondary:focus, .h-settings-button.a-btn.a-btn-secondary:active{background-color:transparent }
.setting-btn:hover{
    background-color: #fff !important;
}