.bg-orange {
  background-image: url("../../../../assets/images/component/orange-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px 20px 40px;
  text-align: center;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  .bg-title {
      font-weight: 500;
      font-size: 20px;
      display: block;
  }

  .sp-label {
      background: #65ABEB;
      border-radius: 2px;
      font-weight: 500;
      font-size: 14px;
      color: #fff;
      padding: 10px;
      display: inline-block;
      position: relative;
      bottom: -20px;
  }
}
.orange-bg-image {
  max-height: 20vh;
  min-height: 150px;
  background-size: cover;
  width: 100%;
}
.position-set {
  max-height: 20vh;
  min-height: 106px;
  background-size: cover;
  width: 100%;
}

.title-header {
  position: absolute;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  h5 {
    color: #ffffff;
    text-align: center;
  }
}

.cards-wrap {
  padding: 30px 220px;
  background: #fff;
  min-height: calc(110vh - 150px);
}

.card-border {
  border-radius: 5px;
  border: 1px solid #D6D6D6;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 52px 16px;
  text-align: center;
  max-width: 240px;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  svg {
    margin-bottom: 20px;
  }

  h3 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 18px;
  }

  p {
    font-size: 12px;
    margin: 0;
    line-height: 16px;
    min-height: 48px;
  }
}

.card-border.active {
  border: 1px solid #EC590A;
}

.form-check {
  margin-top: 42px;
  text-align: center;
  margin-bottom: 60px;
  display: flex;
    align-items: center;
    padding-left: 0;
    justify-content: center;

  label {
    font-size: 14px;
    padding-left: 0;
    text-align: left;
  }

  input {
    width: 16px;
    height: 16px;
    top: -1px;
  }
}

.mr-back{
  margin-top: 20px;
}

.disable-btn-continue {
  background: #F2F2F2;
  border-radius: 6px;
  padding: 11px 24px;
  color: #7D7D7D !important;
  font-weight: 500;
  font-size: 14px;
  color: white;
  pointer-events: none;
}

.enable-btn-continue {
  border-radius: 6px;
  padding: 11px 24px;
  font-weight: 500;
  font-size: 14px;
  background-color: #e05d0c;
  color: white;
  cursor: pointer;
}

.back-btn-continue {
  padding: 11px 24px;
  font-weight: 500;
  font-size: 14px;
  color: #e05d0c;
  cursor: pointer;
}

.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 30px;
  position: fixed;
  width: 100%;
  bottom: 0;
  background:#fff;
}

.footer-link {
  color: #6d6d6d !important;
  font-size: 14px;
  padding-right: 5px;
  padding-left: 5px;
  text-decoration: underline;
  border-right: 1px solid #dddddd;
}

.footer-link:last-child {
  padding-right: 0px;
  border: none;
}

.footer-img img {
  width: auto;
}

.footer-link {
  font-size: 16px;
}

.download-link-container {
  flex-direction: row;
}

footer.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #fff;
}

.footer-btm {
  background: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.a-checkbox input[type=checkbox] {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

@media (max-width: 1024.98px) {
  .cards-block {
    flex-wrap: wrap;
    justify-content: start !important;
    max-width: 520px;
    margin: 0 auto;
    cursor: pointer;
  }

  .card-border {
    margin-bottom: 20px;
    cursor: pointer;
  }

  .form-check {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
@media (max-width: 991.98px) { 
  .cards-wrap {
    padding: 30px 30px 160px;
  }
}
@media (max-width: 834.98px) {
  .cards-block {
    max-width: 520px;
  }
}

@media (max-width: 600px) {
  .footer-wrapper {
    display: block !important;
    text-align: center;
    .footer-links-container {
      flex-direction: column;
      .footer-link {
        margin-bottom: 12px;
        border: none;
        .footer-link:last-child {
          padding-right: 10px;
          margin-bottom: 0;
      }
    }
  }  
}
}

@media (max-width: 575.98px) {
  .cards-block {
    max-width: 260px;
  }
}
