.content-container {
    overflow: auto;

    .a-main-content {
        min-width: 850px;
    }

    .SumoSelect {
        max-width: 340px;
    }
}

.a-form-label {
    font-size: 13px;
    text-transform: uppercase;
}

.section-title {
    font-size: 17px;
    color: #464646;
}

.SumoSelect.a-select>.CaptionCont {
    border: none;
    padding-left: 0;
}

.SumoSelect>.CaptionCont>.search-txt {
    text-indent: 10px;
}

.add-text-button {
    .appkiticon {
        font-size: 20px;
        color: #eb590b;
        vertical-align: middle;
    }
}

.a-list.custom-check {
    .a-list-item {
        padding: 0 0.625rem;
        min-height: 35px;
        line-height: 35px;
        overflow: hidden;

        &:first-child {
            background-color: #e0e0e0;

            .a-checkbox-text {
                font-weight: 500;
            }
        }

        .a-checkbox-text {
            font-weight: 400;
            border-left: none;
            padding-left: 10px;
            margin-left: 30px;
            min-height: 35px;
            display: table;
            padding-right: 60px;
            height: 35px;

            .domain-title-text {
                line-height: 25px;
                display: table-cell;
                vertical-align: middle;

                overflow-wrap: anywhere;
            }
        }

        .a-checkbox {
            input[type="checkbox"] {
                &:checked {
                    &+.a-checkbox-mark {
                        background: #4a4747;
                        border: none;
                    }
                }
            }
        }
    }
}

.footer-dropdowns {
    position: relative;
    display: inline-block;
    width: 100%;
    z-index: 8;

    .dropdown-menu {
        max-width: 200px;
        padding: 10px 0;
        left: 0;
        top: -65px;

        &>li {
            cursor: pointer;
        }

        .user-infor-list {
            border: none;
        
        }
        .user-infor-list-item {
            padding: 10px;

            a:focus {
                outline: none;
                text-decoration: none;
            }
           
        }

        .user-infor-list-item {
            &:hover {
                font-weight: normal;
                background: #eee;
            }
        }
    }
}

.swich-mode-svg {
    margin-right: 10px;
}

.users-container {
    .a-table {
        .a-checkbox input[type="checkbox"]:checked+.a-checkbox-mark {
            background: #5e5e5e;
            border: none;
        }
    }

    .a-table-wrapper .a-dropdown-toggle {
        background-color: #fff;
    }

    .min-w-1024 {
        min-width: 1024px;
    }

    .w-300 {
        width: 300px;
    }

    .w-43 {
        width: 43px;
    }
}

.user-sidebar-container {
    .form-group {
        .a-toggle {
            font-weight: normal;

            .a-toggle-text {
                font-size: 14px;
            }
        }
    }
}

.question-sidebar-container {
    .a-btn-group {
        .a-btn-group-item {
            &:not([disabled]) {
                &.active {
                    background: #eb590b;
                    transition: color 0.25s ease;
                    color: #ffffff;
                    border-color: #eb590b;
                }
            }
        }
    }
}

.lifecycle-stages {

    .a-radio-text,
    .a-checkbox-text {
        font-weight: normal;
    }
}

.custom-check {
    &.hover-effect {
        .a-list-item {
            &:not(:first-child) {
                &:hover {
                    background: #ffe6a1;
                }
            }
        }
    }
}

.table-filter-icon {
    margin-right: 10px;
    min-width: 24px;
}

.filter-row {
    padding-left: 15px;
    margin-bottom: 15px;
}

.active-icon {
    color: #eb590b;
}

.general-row {
    .lifestyle-stages {
        max-height: 300px;
        overflow: auto;

        .a-list-item {
            overflow-wrap: anywhere;
        }
    }
}

.domain-row {
    .domain-list {
        max-height: calc(100vh - 450px);
        overflow: auto;

        .a-checkbox-text {
            cursor: pointer;
            width: 100%;
        }

        &.domain-data {
            li {
                &:first-child {
                    background: #fff;

                    .a-checkbox-text {
                        font-weight: normal;
                    }
                }

                &:hover {
                    background: #ffe6a1;
                }
            }
        }
    }
}

.a-table-scroller {
    max-height: calc(100vh - 450px);
    padding-right: 0;
    min-height: 35px;
}

.black-checkbox {
    &.a-checkbox input[type="checkbox"]:checked+.a-checkbox-mark {
        background: #4a4747;
        border: none;
    }
}

.domain-delete {
    margin-left: auto;
    justify-self: flex-end;
}

.black-checkbox {
    &.a-checkbox input[type="checkbox"]:checked+.a-checkbox-mark {
        background: #4a4747;
        border: none;
    }
}

textarea {
    &.a-text-input {
        height: 133px;
        padding: 10px;
    }
}

.at-pagination {
    .a-pagination {
        justify-content: flex-end;
    }
}

.a-checkbox-cell,
.q-check-cell,
.checkbox-cell {
    width: 40px;
}

.a-domain-cell {
    word-wrap: anywhere;
    word-break: break-word;
}

@media screen and (min-width: 841px) and (max-width: 1200px) {
    .filter-row {
        .col-md-2 {
            -ms-flex: 0 0 33%;
            flex: 0 0 33%;
            max-width: 33%;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 840px) {
    .filter-row {
        .col-md-2 {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    .domain-container,
    .users-container,
    .attributes-container {
        height: auto;
        min-height: 280px;
    }
}

@media screen and (min-width: 320px) and (max-width: 840px) {

    .domain-container,
    .users-container,
    .attributes-container {
        height: auto;
        min-height: 280px;
    }

    .footer-dropdowns {
        margin-top: 10px;
    }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .filter-row {
        padding-left: 0;
    }
}

.mandatory-icon {
    font-size: 13px;
    color: red;
}

.red {
    color: red;
}

.lower-case {
    text-transform: lowercase;
}

.custom-fg {
    align-items: center;
    margin: 25px 0 !important;
    margin-bottom: 40px !important;
}

.custom-fg .a-text-input {
    max-width: 75px;
    margin: 0 10px;
}

.custom-fg>div .error {
    position: ABSOLUTE;
    left: 20px;
    font-size: 11px;
    min-width: 200px;
}

.custom-fg>div {
    position: relative;
}

.custom-fg>div>span {
    position: RELATIVE;
    display: block;
}

.custom-fg>div>span i {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.custom-fg>div>span>input {
    padding-right: 15px;
}

.error.error-bg {
    background: #e7dcdc;
    padding: 8px 12px;
    border-radius: 4px;

    display: none;
    float: none !important;
}

.error.error-bg.active {
    display: inline-block;
}

.error.error-bg.active:before {
    content: "";
    font-family: "appkit-font";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
}

.m-0.custom-fg {
    margin: 0 !important;
    margin-bottom: 15px !important;
}

// filter elements

.text-theme-color {
    color: var(--theme-color-control);
}

a.text-theme-color {
    color: var(--theme-color-control);
    font-size: 13px;

    &:hover,
    &:focus {
        color: var(--theme-color-control);
    }
}

.custom-select-filter {
    p.CaptionCont {
        background-color: transparent;
        border: none;

        span.placeholder {
            padding-right: 10px;
            font-size: 13px;
            color: var(--theme-color-samplecodeicon);
        }

        label {
            position: relative !important;

            i {
                font-size: 12px;
                color: var(--theme-color-samplecodeicon);
            }
        }
    }

    &:hover,
    &:focus {
        p.CaptionCont {
            background-color: transparent;
            border: none;
        }
    }
}

.SettingSumoSelect .SumoSelect:not(.disabled):focus>.CaptionCont,
.SettingSumoSelect .SumoSelect:not(.disabled).open>.CaptionCont {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.SettingSumoSelect {
    .SumoSelect {
        p.CaptionCont {
            background-color: transparent;
            border: none;

            span {
                padding-right: 10px;
                font-size: 13px;
                display: block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden
            }

            span.placeholder {
                padding-right: 10px;
                font-size: 13px;
                color: var(--theme-color-samplecodeicon);
                background: unset;
            }

            label {
                position: relative !important;
                width: 16px !important;

                i {
                    font-size: 12px;
                    color: var(--theme-color-samplecodeicon);
                }
            }
        }

        &.open {
            .optWrapper {
                width: 150px !important;
            }
        }
    }
}

.btn-domain-right {
    position: absolute;
    right: 0;
}


// custom input text with icon

.input-group-with-icon {
    position: relative;

    .custom-input-filter-icon {
        padding-left: 2.5rem;
        position: relative;
        font-size: 13px;
    }

    .custom-input-filter-icon:focus+i {
        color: var(--theme-color-control);
        transform: translate(5px, -50%);
    }

    & i {
        position: absolute;
        left: 0;
        top: 50%;
        line-height: 34px;
        padding: 0;
        color: var(--theme-color-samplecodeicon);
        transition: 0.3s;
        transform: translate(5px, -50%);
    }
}

.archive-model {
    font-weight: 500;
    font-size: 0.875rem;
    color: #2D2D2D;
    cursor: pointer;
}