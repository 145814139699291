.filter-segment-group {
    width: 100%;
    display: flex;
}

.filter-segment-group label {
    width: 48%;
    border: 1px solid grey;
    padding: 10px;
    margin: 1%;
    display: flex;
    flex-direction: row;
}

.filter-segment-group label.sgmnt-only-optn {
    width: 98%;
    border: 1px solid grey;
    padding: 10px;
    margin: 1%;
    display: flex;
    flex-direction: row;
}

.radio-btn-title {
    font-size: 12px;
}

.filters-wrapper {
    margin-top: 0px;
    position: relative;
    top: -2px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
}

.acq-modal .badge-dropdown .dropdown-select {
    width: 226px !important;
    left: 6px !important;
    position: relative;
}
.acc_data .sumo-select .dropdown-menu {
    width: 100%;
    max-width: 400px !important;
    min-width: 200px !important;
}

label.sgmnt-only-optn .acq-modal .badge-dropdown .dropdown-select {
    width: 500px !important;
}

label.sgmnt-only-optn .acq-modal .badge-dropdown .dropdown-select-ul {
    width: 500px !important;
    margin-top: 0px !important;
    margin-left: 12px !important;
}

.acq-modal .cstm-slct-drp-dwn .active {
    border: none;
}

.acq-modal .cstm-slct-drp-dwn .active .dropdown-select {
    border: 2px solid #eb590b;
}

.add-cstm-qstn-mdl .custom-question-filter .selected-filter-wrap {
    padding: 0 !important;
}

.mdl-info {
    display: flex;
    left: 12px;
}

.mdl-info i {
    font-size: 15px !important;
}

.drk-gry-clr {
    color: #6c6c6c !important;
}

.cstm-dmn-info ul {
    padding: 0px !important;
}

.cstm-dmn-info ul li {
    padding-left: 10px !important;
}

.acq-modal .cstm-sgmntdd span {
    width: 447px !important;
}

.a-f2{
    background-color: #F5F5F5 !important;
    color: #2d2d2d !important;
}

.a-modal-footer{
    padding: 20px !important;
    height: auto !important;
}

.placeholder12::placeholder{
    font-size:12px;
}

.ifCustomQuestion{
    font-size: 12px !important;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
    white-space: nowrap;
}

.selected-filter-tag{
    word-wrap: break-word !important;
    white-space: normal !important;
    height: auto !important;
    overflow-wrap: break-word !important;
    max-width:246px !important;
    display: block !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;

}