@font-face {
  font-family: 'Material Icons';
  font-display: block;
}

.no-min-width {
  min-width: auto !important;
}

body small, body text, body a, body div, body p,
body h1, body h2, body h3, body h4, body h5, body h6,
body b, body strong,body li, .term-pd-2 span, .bg-orange span,
html, html form, .text-uppercase, .cstm-sgmntdd span,
.badge-label span, .fade-text, .insufficient-result-span,.port_active,
.tag-title, .custom-question, .open-text-acc, .a-page-subtitle, .close-feedback-form,
.in-active-badge, .p-title, .a-dropdown-item span, .a-ratings-value span, .cc-group-number,
.cc-growth, .txt-grey, .tag-warning span, .first-ccti span, .ccti-element span, .widget-survey-engagement,
.cgc-text, .group, .thumb-title, .close-text-acc, .right-icon, .icon-downtrend-fill span, .r-s-title span,
 .assign-filters, .add-text-button span, .preview-pane-font,.form-fields span,.show-hide-section span,.react-multi-email span, .mandatory-icon {
  font-family: "PwC Helvetica Neue" !important;
}

.add-project-sidebar {
  .a-list {
    .a-list-item {
      .a-badge {
        color: #ffffff;
        display: flex;
        justify-self: flex-end;
        margin-left: auto;
        width: 100%;

        &.a-badge-secondary {
          background-color: #7d7d7c;
        }
      }
    }
  }

  .SumoSelect.a-select>.CaptionCont>span {
    font-size: 13px;
  }

  .text-disabled {
    input:disabled {
      background-color: #f5f5f5;
    }

    .appkiticon {
      color: #989898;
    }
  }
}

.add-stakeholder-sidebar {
  .a-list {
    .a-list-item {
      .a-badge.a-badge-warning {
        color: #ffffff;
        background-color: #ffbf1f;
      }

      .a-badge-dark {
        color: #000000 !important;
      }

      .appkiticon {
        cursor: pointer;
        margin-left: 10px;

        &:hover {
          color: #eb590b;
        }
      }
    }
  }

  .dropdown-assign-filter {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    bottom: 0px;
    top: 40px;
    left: 0px;
    font-size: 13px !important;
    padding: 0.8rem !important;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
  }

  .dropdown-assign-filter:hover .dropdown-content {
    display: block;
  }

  .import-csv-icon {
    border: 0.0625rem solid var(--theme-color-control);
    color: var(--theme-color-control);
    align-items: center;
    display: flex;
    position: relative;

    .import-csv-drop-down {
      position: absolute;
      z-index: 1;
      right: 0px;
      top: 101%;
      display: none;

      .user-infor-list {
        background: #fff;
        width: 150px;
        color: #000;
      }

      .user-infor-list-item {
        font-size: 12px !important;
        padding: 10px !important;
      }
    }

    &:hover .import-csv-drop-down {
      display: block !important;
      background: #eee !important;
    }

    .user-infor-list-item:hover {
      background: #eee !important;
    }

  }

}

.import-csv-icon:hover {
  background-color: var(--theme-color-btnsechover);
  color: var(--theme-color-control);
}

.a-main-content {
  min-height: calc(100vh - 200px);
}

.cm-color {
  color: #eb590b;
}

.add-project-sidebar {
  .SumoSelect.a-select>.CaptionCont>span {
    font-size: 13px;
  }

  .text-disabled {
    input:disabled {
      background-color: #f5f5f5;
    }

    .appkiticon {
      color: #989898;
    }
  }
}

.add-stakeholder-sidebar {
  .r-s-body {
    p {
      font-size: 12px;
      line-height: normal;
    }

    .cust-stake-buttons {
      a {
        font-size: 14px;
      }
    }
  }

  .a-list {
    .a-list-item {
      .a-badge {
        color: #ffffff;
        display: flex;
        justify-self: flex-end;
        margin-left: auto;

        &.a-badge-secondary {
          background-color: #7d7d7c;
        }
      }

      .appkiticon {
        cursor: pointer;
        margin-left: 10px;

        &:hover {
          color: #eb590b;
        }
      }
    }
  }
}

.capitalize-text {
  text-transform: capitalize;
  cursor: pointer;
}

.icon-downtrend-color {
  color: #bb6666 !important;
}

.a-main-content {
  min-height: calc(100vh - 132px);
}


.data-container {
  &.masonry-layout {
    // display: inline-flex;
    // flex-direction: column;
    // flex-wrap: wrap;
    max-height: calc(100vh - 250px);
  }

  .data-item-box {
    width: 280px;
    cursor: pointer;

    &.project-box {
      cursor: default;

      .project-title {
        .capitalize-text {
          cursor: default;
        }
      }
    }

    margin-bottom: 10px;

    .a-panel {
      position: relative;
      padding: 25px 20px;

      .a-accordion-wrapper {
        padding: 0;
        margin-bottom: 20px;

        .a-accordion-content {
          padding: 0;

          .project-section-title {
            vertical-align: middle;
            font-size: 0.8rem;
            font-weight: 500;
            color: #989898;
            text-align: left;
            margin-bottom: 10px;

            i {
              font-size: 22px;
              color: #747475;
              vertical-align: middle;
            }
          }

          .tag-container {
            .a-tag {
              width: 100%;
              justify-content: flex-start;
              display: flex;
              margin-bottom: 10px;
              white-space: normal;
              text-align: left;
              position: relative;
            }

            .tag-blue {
              background-color: #e5f0ed;

              .right-icon {
                color: #66a295;
              }
            }

            .tag-warning {
              background-color: #ffe7ad;

              .right-icon {
                color: #deb450;
              }
            }

            .tag-danger {
              background-color: #f8d3cf;

              .right-icon {
                color: #e23d2c;
              }
            }

            .tag-grey {
              background-color: #eaeaea !important;
              color: var(--theme-color-888888);
            }

            .left-icon {
              margin-right: 10px;
            }

            .tag-title {
              font-size: 83%;
              color: #5a5a5a;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              height: 13px;
            }

            .right-icon {
              font-size: 0.85rem;
              margin-left: auto;
              justify-self: flex-end;
            }
          }

          .people-comments-section {
            text-align: left;

            .a-badge {
              text-transform: none;
              border-radius: 2px;
              padding: 15px 5px;
              margin-right: 10px;
              margin-bottom: 10px;
              cursor: pointer;
              font-weight: 400;

              &.badge-danger {
                background: #c52a1b;
              }

              &.badge-primary {
                background: #fa5927;
              }

              &.badge-warning {
                background: #ffbf1f;
              }

              &.badge-success {
                background: #23982e;
              }

              &.badge-general {
                background: #7d7d7c;
              }
            }

            .no-resp-data {
              font-size: 12px;
              text-align: center;
              color: #a7a7a7;
            }
          }

          .survey-section {
            .a-customized-ratings {
              .a-ratings-detail {
                margin-bottom: 5px;

                span {
                  font-weight: normal;
                  font-size: 12px;
                }

                .a-ratings-total {
                  width: 100%;
                  height: 8px;
                  position: relative;
                  background-color: #eee;
                  margin-top: 30px;

                  .average-marker {
                    position: absolute;
                    height: 25px;
                    width: 2px;
                    background: #e0301e;
                    top: -8px;

                    &[data-value="10"] {
                      left: 10%;
                    }

                    &[data-value="20"] {
                      left: 20%;
                    }

                    &[data-value="30"] {
                      left: 30%;
                    }

                    &[data-value="40"] {
                      left: 40%;
                    }

                    &[data-value="50"] {
                      left: 50%;
                    }

                    &[data-value="60"] {
                      left: 60%;
                    }

                    &[data-value="70"] {
                      left: 70%;
                    }

                    &[data-value="80"] {
                      left: 80%;
                    }

                    &[data-value="90"] {
                      left: 90%;
                    }

                    &[data-value="100"] {
                      left: 100%;
                    }
                  }

                  .a-ratings-percent {
                    height: 100%;
                    background-color: #634a8f;
                  }
                }
              }
            }

            .a-ratings-value {
              font-size: 0.75rem;
              font-weight: 500;
              color: #a6a6a6;

              .m-r-10 {
                margin-right: 10px;
              }

            }

            .cstm-ratings-value {
              position: absolute;
              top: 100%;
              width: 240px;
              left: -1000%;
              word-wrap: break-word;
              display: inline-flex;
            }
          }
        }
      }

      .panel-expander {
        position: absolute;
        bottom: 5px;
        padding-left: 0;
        left: 50%;
        transform: translateX(-50%);

        i {
          font-size: 22px;
          color: #a6a6a6;
          margin-top: 11px;

          &:hover {
            color: #eb590b;
          }

          &.icon-up-chevron-fill:before {
            content: "";
          }
        }
      }

      .project-title {
        margin-bottom: 20px;

        p {
          color: #a6a6a6;
          overflow-wrap: anywhere;
          word-break: break-word;
        }
      }

      .project-percentage {
        margin-bottom: 10px;

        h3 {
          color: #6d6d6d;
          font-size: 32px;

          &.no-oc-data {
            position: relative;
            z-index: 0;

            &:before {
              position: absolute;
              z-index: 1;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              background: #c7c7c7;
              content: "";
              filter: blur(3px);
              -webkit-filter: blur(3px);
              height: 37px;
              width: 90px;
              display: block;
              margin: 0 auto;
            }

            span {
              opacity: 0;
            }
          }
        }

        &.project-page {
          i {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            font-size: 25px;
            color: #545454;
          }

          h3 {
            display: inline-block;
            vertical-align: middle;
            width: 65px;

            &:before {
              width: 65px !important;
            }
          }
        }
      }

      .project-progress {
        margin-bottom: 10px;

        p {
          font-size: 96%;
          color: #a6a6a6;

          i {
            color: #66bb6a;
            position: relative;
            min-width: 32px;
          }

          .grey-color {
            color: #a6a6a6 !important;
          }

          &.no-trends-data {
            .no-min-width {
              min-width: 32px !important;
            }

            i {
              &:before {
                position: absolute;
                z-index: 1;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: #c7c7c7;
                content: "";
                filter: blur(1px);
                -webkit-filter: blur(1px);
                width: 27px;
                display: block;
                margin: 0 auto;
              }

              span {
                opacity: 0;
              }
            }
          }
        }
      }

      .graph-line {
        margin-bottom: 15px;
        margin-top: 5px;
        position: relative;

        p {
          color: #a6a6a6;
        }
      }
    }
  }
}

.widgets-grid-result {
  display: inline-flex;
  height: 30rem;
  flex-direction: column;
  flex-wrap: wrap;
}

.no-response.text-center {
  font-size: 13px;
  color: rgba(60, 60, 60, 0.5);
  margin: 0.4rem 0;
}

.widgets-grid {
  display: flex;
  height: 30rem;
  flex-direction: column;
  flex-wrap: wrap;
}

.create-seg-btn:before {
  content: '+';
  padding-right: 0.2rem;
  transform: translateY(-9%);
}

.graph-tooltip {
  position: absolute;
  z-index: 999;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 2px 10px;
  font-size: 12px;
  width: auto;
  text-align: left;
  border-radius: 3px;
}

span.graph-tooltip:before {
  content: "";
  border: 10px solid transparent;
  position: absolute;
  border-bottom-color: rgba(0, 0, 0, 0.5);
  top: -20px;
}

.stack-holder-timeline {
  .graph-tooltip {
    background: #fff;
    color: #333;
    font-size: 10px;
    width: auto;
    text-align: center;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.5);
  }

  .graph-tooltip:before {
    left: 50%;
    transform: translateX(-50%);
    content: "";
    border: 10px solid transparent;
    position: absolute;
    border-top-color: #fff;
    bottom: -15px;
  }

  .date {
    color: rgba(0, 0, 0, 0.5);
  }
}

.blur-graph {
  filter: blur(3px);
}

.r-s-subtitle {
  .a-form-label {
    font-size: 14px;
    color: #828282;
  }
}

.messaging {
  .incoming_msg_img {
    display: inline-block;
    width: 10%;

    img {
      width: 100%;
    }
  }

  .received_msg {
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: top;
    width: 88%;
    margin-bottom: 20px;

    p {
      background: #e0e0e0 none repeat scroll 0 0;
      border-radius: 4px;
      color: #2f2f2f;
      font-size: 13px;
      margin: 0;
      padding: 5px 10px 5px 12px;
      width: 100%;
    }

    .time_date {
      color: #747474;
      display: block;
      font-size: 12px;
      margin: 3px 0 0;
      text-align: right;
    }
  }
}

.projects-page {
  max-height: calc(100vh - 170px);

  #launchConfirmation {
    .a-modal-header {
      padding: 15px 15px 0px 15px !important;
    }

    .a-modal-body {
      padding: 0px 15px 15px 15px !important;
    }

    .a-modal-footer {
      padding: 15px !important;
    }

    .a-modal-body {
      .p-s-tag {
        margin-bottom: 10px !important;
      }
    }
  }

  .fixed-content {
    position: sticky;
    z-index: 99999;
    height: 100px;
    background-color: rgb(232, 232, 232);
  }
}

.projects-container {
  .dropdown-select-ul {
    width: 263px
  }
}

.projects-page .data-scroller-container {
  overflow: auto;
  min-height: auto;
  height: calc(100vh - 340px);
  margin-top: 25px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #7f7f7f;
  }
}

.col-t1 {
  float: left;
  width: 280px;
  margin-right: 20px;
  padding: 0;
}

.project-stakeholder {
  .stakeholder-name {
    line-height: 16px;
    min-width: 190px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &.stackholder-paused {
      color: grey
    }
  }
}

@media (min-width: 1200px) {
  .excel-download {
    display: block;
  }
}

@media screen and (min-width: 320px) and (max-width: 1200px) {
  .data-scroller-container {
    height: calc(100vh - 240px);
  }
  .excel-download {
    display: none;
  }
}

@media only screen and (max-width: 1042px) {
  .custom-stake-settings {
    justify-content: flex-start !important;
  }
}

@media only screen and (min-width: 1240px) {
  .data-container {
    &.custom-data-container {
      display: unset !important;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .data-container {
    &.custom-data-container {
      display: unset !important;
    }
  }
}

.tooltip {
  width: auto;
}

@media screen and (min-width: 1900px) {
  .widgets-grid {
    height: 22rem;
  }
}

.swap-tooltip {
  width: auto;
  height: auto;
  font-size: 16px;
  padding: 2px !important;
  margin: 2px !important;
}

.disable-swap-tooltip {
  width: auto;
  height: auto;
  font-size: 16px;
  padding: 2px !important;
  margin: 2px !important;
}

.pop-out-help i {
  font-size: 18px !important;
  color: #bababa !important;
  cursor: pointer;
  margin-left: 5px;
}

.indicate_val {
  font: 14px;
  padding-top: 9px;
}

.r-s-header .indicate_val .in_text {
  color: #464646 !important;
  font-size: 13px !important;
}

.mobile_tooltip {
  height: 110px;
  width: 230px;
}

.dash_tooltip {
  height: 90px;
  width: 180px;
}

.completion_tooltip {
  height: 70px;
  width: 310px;
}

.t-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.relevant-question li i {
  color: #eb590b;
}

.relevant-question li {
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  margin-bottom: 0 !important;
}

.relevant-question li i {
  color: #eb590b;
  font-size: 13px !important;
}

.relevant-question li:first-child {
  border-top: 1px solid #ccc;
}

.open-text-selected-item {
  font-size: 14px;
  font-weight: normal;
  color: #c7c7c7;
}

.pro-reset-button i {
  color: #666;
}

.pro-reset-button.reset-button a {
  font-size: 14px;
}

.cstm-align {
  position: relative;
  top: 3px;
}

.filter-loading {
  .loader-text {
    color: #eb590b;
  }
}

.insuficient-img {
  background-image: url("../../../../assets/images/component/insufficient-results.svg");
  width: 3.6rem;
  height: 4rem;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.insuficient-img-sml {
  background-image: url("../../../../assets/images/component/insufficient-results.svg");
  width: 2rem;
  height: 2rem;
  background-size: contain;
  background-repeat: no-repeat;
  float: left;
  margin-left: 25px;
  margin-right: 15px;
  // margin-top: 2px;
}

.right-bar-close button {
  color: #a3a3a3;
  padding: 5px;
  box-shadow: none !important;
  width: 36px;
  height: 36px;
}

.header-subline span {
  color: #a3a3a3;
  font-size: 16px;
  font-weight: 700;
}

.right-bar-title h4 {
  font-size: 22px;
}

.right-bar-accordion .card-header:after {
  font-family: "Material Icons";
  content: "\e313";
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
  font-weight: 600;
  transition: 0.3s linear;
}

.right-bar-accordion .card-header.collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\e313";
}

.right-bar-accordion .card-header {
  padding: 0px;
  background-color: transparent;
  border: none !important;
}

.right-bar-accordion .card {
  border: none;
  border-radius: 0px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  padding-top: 0;
  text-align: inherit;
  box-shadow: none;
  height: auto;
}

.right-bar .message-box p {
  font-size: 13px;
  margin-top: 0;
}

.right-bar {
  .top-section {
    padding: 20px;
    background-color: #f8f8f8;
  }

  .middle-section {
    padding: 20px;

    .comments-heading-wrap1.p-28.p-b-0 {
      padding-bottom: 0 !important;
    }

    .comments-heading-wrap1.p-28.p-t-0 {
      padding-top: 0 !important;
    }
  }
}

.right-bar .message-box {
  width: 100%;
}

.right-bar-accordion .card:hover {
  transform: scale(1);
}

.right-bar-accordion .card-title {
  padding-left: 28px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  user-select: none;
  padding-left: 28px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  user-select: none;
  margin-bottom: 10px;
  line-height: 1.5;
  height: auto;
}

.right-bar-accordion .card-title span {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
}

.right-bar-accordion .card-title span.skip-next-color.skip-icon-cstm-size {
  font-size: 1.2rem;
}

.right-bar-accordion .card-body {
  padding: 0px 12px;
  padding-top: 0 !important;
}

.message-avatar {
  background: #eaeaeb;
  border-radius: 50%;
  color: #a3a3a3;
}

.message-avatar span {
  font-size: 18px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-box {
  background: #e0e0e0;
  font-size: 12px;
  margin-left: 8px;
  padding: 8px 12px;
  border-radius: 10px;
}

.comment-tag-blue {
  background: #e5f0ed;
}

.comment-tag-danger {
  background: #f8d3cf;
}

.comment-tag-grey {
  background: #e0e0e0;
}

.thumb-up-color {
  color: #759e93;
}

.thumb-down-color {
  color: #f17b6f;
}

.skip-next-color {
  color: #7d7d82;
}

.orange-bar-wrap {
  height: 3px;
  background-color: #eaeaea;
  margin-top: 5px;
  border-radius: 6px;
}

.orange-bar {
  background-color: #eb590b;
  height: 3px;
  width: 50%;
  border-radius: 6px;
}

.comments-heading-wrap h6 {
  color: #eb590b;
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
}

.stack-holder-timeline {
  .comments-heading-wrap1 {
    text-align: center;
  }

  .graph-line {
    position: relative;
    margin-top: 25px;
  }

  .segment-name {
    color: #a6a6a6;
  }
}

.multiple-segment-trend-line {
  .header {
    display: flex;
    justify-content: space-between;

    .group {
      padding: 10px 0px;
      border-bottom: 2px solid lightgray;
      width: 100%;
      color: lightgray;
    }

    .group-number {
      padding: 10px 0px;
      border-bottom: 2px solid lightgray;
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    .group-number.active {
      border-bottom: 3px solid #fa5928;
      color: #fa5928;
    }
  }
}

.comments-heading-wrap1 {
  h6 {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    padding-left: 10px;
  }
}

.comments-heading-wrap1.qa-title {
  margin-top: 4rem !important;
}

.message-box p {
  line-height: 16px;
  margin-bottom: 0px;
}

.message-info {
  color: #b0b0b0;
  display: block;
  text-align: right;
  font-size: 10px;
  margin-top: 5px;
}

.right-bar-accordion .card-header:not(.collapsed):after {
  transform: rotate(180deg);
}

.card-body.ad-comments.collapsed .nested-card-title:after {
  transform: rotate(180deg);
}

.card-body.text-toggle.card-open .nested-card-title:after {
  transform: rotate(0deg);
}

.card-body.text-toggle:not(.card-open) .nested-card-title:after {
  transform: rotate(180deg);
}

.custom-animation .card-body.collapse:not(.show) {
  padding: 0 !important;
  display: block;
  overflow: hidden;
  height: 0;
  transform: scaleY(0);
}

.custom-animation .card-body.show {
  display: block;
  overflow: hidden;
  // transition: transform 0.2s ease-out;
  height: auto;
  transform: scaleY(1);
  transform-origin: top;
}

.title {
  color: #a3a3a3;
}

.phrase-color {
  color: #333 !important;
}

small.phrase-color.d-font-12 {
  font-size: 12px;
}

.g-text-wrap {
  color: #7d7d7c;
}

.g-text-wrap h5 {
  margin: 0;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: #7d7d7c;
}

.g-text-wrap span {
  font-size: 11px;
  font-weight: 500;
}

.g-date-icon span {
  font-size: 22px;
  color: #bbbbbb;
  margin-top: 5px;
}

.g-divider {
  width: 0;
  border-right: 1px solid #cecece;
  height: 24px;
  margin: auto 1rem;
}

.g-timeing-wrap {
  vertical-align: top;
  margin-top: 6px;
}

.no-comment-wrap {
  margin-top: 40px;
}

.no-comment-text {
  padding: 0px 40px;
  color: #c5c5c5;
  font-size: 1rem;
}

.tag-hover-menu {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -35px;
  z-index: 999;
  height: 35px;
  background: #ffffffe0;
  border-radius: 0 0 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 7px #cccccc8f;
  opacity: 0;
  visibility: hidden;
  // transition: all ease-in-out 0.5s;
  // transition-delay: 1s;
}

.tag-hover-menu span {
  height: 35px;
  width: 50%;
  text-align: center;
  cursor: pointer;
  transition: 0.2s linear;
}

.custom-menu:hover .tag-hover-menu {
  opacity: 1;
  visibility: visible;
  transition: all ease-in-out 0.5s;
  transition-delay: 1s;
}

.data-container .data-item-box .a-panel .a-accordion-wrapper .a-accordion-content .tag-container .a-tag {
  border-radius: 4px;
}

.tagnumber {
  border: 1px solid #eb590b;
}

.a-tag .tag-number-badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #eb590b;
  font-size: 10px;
  color: #fff;
  padding: 3px;
  border-radius: 2px;
  width: 14px;
  height: 14px;
  text-align: center;
}

.data-item-box.project-box .a-accordion-content {
  overflow: visible;
}

.right-bar-title h4 {
  font-size: 18px;
  font-weight: 400;
  color: #323232;
}

.right-bar-title .header-top-tagline {
  color: #888888;
  font-weight: 400;
  font-size: 14px;
}

.rb-description-wrap {
  margin-top: 15px;
}

.rb-description-wrap span {
  color: #323232;
  font-size: 12px;
  line-height: 17px;
  display: block;
}

.progress-bar.green-p-bar {
  background: #73a598;
}

.progress-bar.red-p-bar {
  background: #cd6860;
}

.rb-progress-wrap {
  margin-top: 15px;
}

.rb-progress-wrap .progress {
  border-radius: 3px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.green-p-bar {
  color: #73a598;
}

.red-p-bar {
  color: #cd6860;
}

.p-bar-label {
  font-size: 9px;
  margin-bottom: 3px;
}

.p-label-icon {
  font-size: 10px;
  margin-right: 2px;
}

.rb-info small {
  display: flex;
  align-items: center;
  font-size: 9px;
  color: #d0d0d0;
}

.rb-info small span {
  font-size: 14px;
  margin-right: 3px;
}

.right-bar-accordion.custom-questions .card-title span {
  display: block;
  font-size: 13px;
  font-weight: 400;
}

.right-bar-accordion.custom-questions .card-body {
  padding: 0px 12px;
  padding-left: 25px;
}

.rb-answer-wrap .rb-answer-text {
  color: #ababab;
  font-size: 14px;
  display: block;
  line-height: 18px;
  text-align: center;
  font-weight: 300;
}

.custom-questions-title {
  font-size: 16px;
  font-weight: 700;
  color: #323232;
  display: flex;
  align-items: center;
}

.custom-questions-title span {
  color: #b2b2b2;
  font-size: 14px;
  margin-left: 5px;
  margin-top: 3px;
}

.rb-outlined-btn {
  box-shadow: none !important;
  color: #db622b;
  border-color: #db622b;
  display: inline-flex;
  font-size: 14px;
  align-items: center;
  transition: 0.3s linear;
}

.rb-outlined-btn:hover {
  color: #fff;
  background: #db622b;
}

.rb-outlined-btn span {
  font-size: 20px;
  margin-right: 5px;
}

span.rs-section-head {
  font-size: 12px;
  font-weight: bold;
  background: #f4f4f4;
  padding: 7px 0px 7px 10px;
  display: block;
}

.rs-question-container {
  display: flex;
  justify-content: space-around;
}

.rs-question-container {
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
  align-items: flex-start;
  border-bottom: 2px solid #eee;
}

.rs-actions a:last-child {
  margin-left: 10px;
}

.rs-actions a span {
  color: #d04a02;
}

.rs-question-container>span {
  margin: 0 10px;
  font-size: 13px;
  margin-left: 0;
}

.question-number {
  margin-bottom: 5px;
  font-size: 12px;
  opacity: 0.4;
  text-align: right;
}

.custom-warning-notification.a-notification.warning {
  background-color: #f6c343;
  color: #2d2d2d;
  position: fixed;
  top: 82px;
  z-index: 9 !important;
  left: 260px;
  right: 0;
  width: calc(100vw - 275px);
}

.custom-warning-notification.a-notification.warning span {
  font-size: 13px;
  font-weight: bold;
  margin: -1px 0px 2px;
}

button.btn.btn-preview.btn-notice {
  background: #fff;
  border: 1px solid #dc6830;
  height: 29px;
  margin-left: 10px;
  color: #dc6830;
}

.custom-warning-notification.a-notification.warning .notification-content {
  padding: 0.5rem 2rem;
  height: 45px;
}

.custom-warning-notification.a-notification.warning .btn-notice {
  width: 110px;
  padding: 3px;
  box-shadow: none !important;
  font-size: 13px;
}

.custom-warning-notification.a-notification.warning .close-btn {
  display: none;
}

.rs-footer-buttons {
  display: flex;
  align-items: center;
}

button.link-text {
  background: transparent;
  color: #eb590b;
}

.rs-footer-buttons button {
  font-size: 13px !important;
  font-weight: normal;
  font-family: "PwC Helvetica Neue";
}

button.link-text:focus {
  outline: none;
}

.deletemessage-color {
  color: #d04a02;
}

span.quest-text {
  width: 150px;
  display: block;
  // word-break: break-all;
}

.deletemessage-color {
  color: #d04a02;
}

.sumo-icon-container {
  display: flex;
  align-items: center;
}

.sumo-icon-container>span {
  width: 5%;
  font-size: 15px;
  opacity: 0.5;
  margin-top: -6px;
}

.sumo-icon-container>div {
  width: 91%;
}

p.freq span {
  display: block;
  width: 70px;
  margin: 0 5px;
}

p.freq {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.tag-hover-menu .disable {
  pointer-events: none;
}

.tag-hover-menu .enable {
  pointer-events: auto;
}

.a-alert.custom-alert.a-alert-hide {
  z-index: -1;
  visibility: hidden;
}

.a-alert.custom-alert.a-alert-show {
  z-index: 99999;
}

.rs-actions a span {
  margin: 0;
  font-size: 13px;
}

.pointer {
  cursor: pointer;
}

.card-body button {
  width: 100%;
  margin: 4px 0px;
}

.card-body .nested-card-title {
  align-content: center;
  display: flex;
  justify-content: center;
  color: #eb590b;
  line-height: 1.5;
  font-size: 1.75rem;
  padding-bottom: 10px;
  width: 100%;
  margin-top: 15px;

  span {
    cursor: pointer;
  }
}

.card-body .nested-card-title:hover {
  color: #eb590b;
}

.card-body .nested-card-title::after {
  font-family: "Material Icons";
  content: "";
  float: left;
  top: 0;
  left: 0;
  font-size: 15px;
  font-weight: 600;
  transition: 0.3s linear;
}

.cstm-align.a-btn.a-btn-lg.a-btn-secondary:disabled {
  background: transparent !important;
  opacity: 0.6 !important;
}

.a-btn.a-btn-secondary:disabled {
  background-color: transparent !important;
  opacity: 0.6;

  &:hover {
    background-color: transparent;
  }
}

.add-stakeholder-modal {
  background: rgba(0, 0, 0, 0.3);

  .manage-port-apply {
    .a-btn.a-btn-primary {
      color: var(--theme-color-purewhite) !important;
    }
  }
}

.right-bar-body.r-scroll-bar {
  max-height: calc(100vh - 255px);
  min-height: calc(100vh - 255px);
  overflow-y: auto;
}

.right-bar-body.r-scroll-bar::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
  height: 4px;
}

.right-bar-body.r-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
}

.right-bar-body.r-scroll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.square-btn {
  width: 2.325rem;
  padding: 0px !important;
}

.tag-hover-menu span .disable {
  pointer-events: none;
  opacity: 0.5;
}

.project-ques-sidebar .card-title {
  justify-content: space-between;
  margin-bottom: 0;
  height: auto;
  padding-right: 15px;
  padding-top: 5px;
}

.project-ques-sidebar .card:hover {
  background-color: #eee;
}

.project-ques-sidebar .card.card-open:hover {
  background-color: transparent;
}

.project-ques-sidebar .card.border-bottom {
  padding-bottom: 0;
  margin-bottom: 0;
  padding: 5px 0px 10px;
}

.w-h-a {
  width: auto !important;
  height: auto !important;
}

.text-toggle .open-text-acc {
  display: none;
}

.text-toggle.card-open .open-text-acc {
  display: block;
}

.text-toggle.card-open .close-text-acc {
  display: none;
}

.right-bar-accordion.project-ques-sidebar .card-header:after {
  top: 10px;
}

.right-bar-accordion.project-ques-sidebar .card-header.card-open {
  margin-bottom: 10px;
}

.progress-reset .multicolor-bar {
  margin-bottom: 0;
  padding: 0;
}

.card-body .multicolor-bar {
  margin-left: 20px;
  padding: 8px 0px 15px 0px;
}

span.info-text.small {
  font-size: 11px;
  opacity: 0.5;
  display: inline-block;
  margin: 20px 0px 0px;
}

span.info-text.small .material-icons {
  font-size: 12px;
  vertical-align: middle;
  margin-right: 2px;
}

.custom-question-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

span.custom-question-icon {
  margin: 0 5px;
  margin-right: 0px;
  line-height: 0;
}

.custom-question-wrapper>.material-icons {
  margin-top: 4px;
}

.hover-black-btn {
  padding: 0 !important;
  color: #fa5927;
  background: transparent !important;
  outline: none !important;

  &:hover {
    color: #2d2d2d;

    text {
      text-decoration: underline;
    }
  }
}

.v-divider {
  display: inline-flex;
  vertical-align: middle;

  .d-line {
    height: 1.8rem;
    width: 2px;
    background: #d8d8d8;
    margin: 0px 5px;
    display: inline-block;
  }
}

.custom-question-wrapper>.material-icons {
  margin-top: 4px;
}

.ottq-selector {
  text-transform: uppercase;
  font-size: 0.77rem;
  cursor: pointer;
  background-color: transparent;
  color: #fa5927;
  font-weight: 500;
}

.customise-modal-wrap {
  right: -24px;
  position: absolute;
  width: 600px;
  top: 40px;
  z-index: 99;
  display: none;
}

.customise-modal-wrap.open {
  display: block;
}

.customise-container {
  background: #fff;
  max-width: 600px;
  border-radius: 5px;
  box-shadow: 2px 2px 7px #0000002b;
  position: relative;
}

.customise-container:before {
  content: "";
  width: 0;
  top: -25px;
  right: 70px;
  position: absolute;
  height: 0;
  border-style: solid;
  border-width: 0 15px 25px 15px;
  border-color: transparent transparent #ffffff transparent;
}

.ci-description {
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;
}

.customise-header {
  padding: 10px 15px;
}

.customise-title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 5px 0px 0px;

  h3 {
    font-weight: 600;
    font-size: 20px;
    color: #282828;
  }
}

.customise-body {
  padding: 5px 15px;
}

.c-modal-description {
  font-size: 12px;
  color: grey;
  margin-top: 0;
  text-align: left;
}

.ci-info-icon {
  position: absolute;
  right: 25px;
}

.ci-info-icon span {
  font-size: 15px;
  color: #bfbfbf;
}

.ci-description {
  font-size: 12px;
  padding: 0px 10px;
  margin-top: 8px;
  height: 48px;
  color: grey;
}

.ci-title {
  font-size: 14px;
  font-weight: bold;
  padding: 0px 10px;
  text-align: center;
}

.customise-item-wrap {
  text-align: center;
  border-radius: 6px;
  border: 1px solid #d6d6d6;
  cursor: pointer;
}

.ci-tag {
  background: #eedaa8;
  color: #fff;
  position: absolute;
  top: 1px;
  left: 9px;
  padding: 0px 5px;
  font-size: 12px;
  border-top-left-radius: 5px;
}

.ci-icon {
  width: 50px;
  height: 50px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;

  &.ci-icon-custom svg {
    width: 27px;
    height: 23.53px;
    overflow: visible;
  }
}

.ci-icon i {
  font-size: 20px;
  color: #282828;
}

.ci-scroll {
  padding: 0px 10px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 375px;
}

.customise-footer {
  padding: 10px 20px;
  border-top: 1px solid #d8d8d8;
}

.ci-scroll::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
  height: 4px;
}

.ci-scroll::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
}

.ci-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.customise-item-wrap.select-ci {
  border-color: #fa5927;
}

.ci-footer-text {
  font-size: 14px;
  font-weight: bold;
  color: #282828;
}

.d-font-10 {
  font-size: 10px;
}

.ottq-selector {
  text-transform: uppercase;
  font-size: 0.77rem;
  cursor: pointer;
  background-color: transparent;
  color: #fa5927;
  font-weight: 500;
}

.bkgd-color-grey {
  background-color: #f5f5f5;
}

input[name="searchQuestion"] {
  font-size: 0.75rem;
}

.modal-table-head {
  font-weight: 600 !important;
  background-color: transparent !important;
}

.modal-title {
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.a-modal.modal.add-stakeholder-modal .modal-dialog .modal-content .a-modal-footer button.ottq-submit-btn {
  text-transform: none;
}

.domain-title-e {
  text-transform: uppercase;
  font-size: 12px !important;
  color: #a3a3a3 !important;
}

.p-28 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.d-font-10 {
  font-size: 10px;
}

.attribute-pill {
  height: 2.35rem !important;
  cursor: pointer;
}

.attribute-add {
  font-size: 13px;
  color: #fa5927;
}

.attribute-add:hover {
  color: #fa5927;
}

.body-input-container i {
  color: #fa5927;
  cursor: pointer;
}

.main-textbox {
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  padding: 0 20px;
  padding-bottom: 10px;
  margin-top: 20px;
}

.dropdown-icon .footer-dropdowns .dropdown-menu {
  max-width: max-content;
}

.custom-tag.left-0 {
  left: 0;
}

.dropdown-icon {
  position: absolute;
  top: 3px;
  right: 10px;
}

.main-textbox .a-text-input {
  height: 45px;
  font-size: 18px;
  text-align: center;
}

.custom-ques-box-body .a-text-input {
  height: 40px;
  margin-right: 10px;
}

.custom-ques-box-body {
  padding: 0 20px;
  margin-bottom: 20px;
  max-height: 400px;
  overflow: auto;
  padding-top: 10px;
}

.body-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #f1f1f1;
}

.custom-ques-box-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.custom-ques-box-body::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
  height: 4px;
}

.custom-ques-box-body::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
}

.custom-ques-box-footer {
  padding: 0 20px;
  border-top: 1px solid #dddddd;
  padding-top: 8px;
}

.custom-tag {
  position: absolute;
  background: #fbe8b4;
  font-size: 11px;
  padding: 2px 5px;
  top: 0;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
  border-radius: 0 0 3px 0;
}

.crossIcon span {
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
  color: #999;
}

.main-icon span {
  color: #999;
  font-size: 35px;
}

.crossIcon {
  text-align: right;
}

.optional-comment-custom-tag {
  width: fit-content;
  background: #fbe8b4;
  font-size: 11px;
  padding: 2px 5px;
  top: 0;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
  border-radius: 3px;
  margin-top: 6px;
}

span.q-icons span {
  opacity: 0.7;
  font-size: 20px !important;
  display: inline-block !important;
}

// .custom-question-container span.custom-question {
//   width: 90%;
// }

span.q-icons {
  // width: 10%;
  display: block;
  position: relative;
  text-align: right;
}

.overflow-v {
  overflow: visible !important;
}

span.q-icons .dropdown-icon {
  position: static;
  display: inline-block !important;
  // margin-left: 15px;
}

span.q-icons .dropdown-icon .footer-dropdowns .dropdown-menu {
  width: 100px;
  min-width: 100px;
  padding: 0;
  right: 0px;
  left: auto;
  top: 20px;
}

span.q-icons .footer-dropdowns .dropdown-menu .user-infor-list .user-infor-list-item {
  padding: 0;
}

span.q-icons .footer-dropdowns .dropdown-menu .user-infor-list .user-infor-list-item a {
  font-size: 12px;
  padding: 5px 10px;
  display: block;
}

ul.custom-q-header {
  padding: 0 20px;
}

ul.custom-q-header ul li {
  font-size: 12px;
}

ul.custom-q-header li {
  font-size: 12px;
  padding-left: 30px;
  position: relative;
}

ul.custom-q-header li:before {
  content: "";
  background: #222;
  width: 5px;
  height: 5px;
  position: absolute;
  left: 0;
  border-radius: 50%;
  top: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.attribute-cell-container {
  display: inline-block;
  width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.question-cell-conatiner {
  display: inline-block;
  width: 590px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cell-container {
  display: inline-block;
  width: 90px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.main-icon-cstm-domain {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  font-size: 20px;
  color: #545454;
}

.pb-25 {
  padding-bottom: 25px;
}

.small-text {
  margin-left: 20px;
}

.disable-yesno {
  opacity: 0.5;
}

.a-btn.a-btn-transparent:disabled {
  background-color: #bdbdbd !important;
}

.orange-color {
  color: #eb590b;
}

.cstm-dmn-del-btn {
  background: transparent;
  float: left;
}

.react-multi-email {
  width: 100%;
  min-height: 200px !important;
  min-height: 200px !important;
  height: 200px !important;
}
.react-multi-email-test {
  width: 100%;
  min-height: 200px !important;
  min-height: 100px !important;
  height: 115px !important;
}


.react-multi-email.focused {
  box-shadow: 0 0 0 0.025rem #fa5927 !important;
  border-color: #fa5927 !important;
}

.rml-count {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.6);
}

.react-multi-email-error {
  box-shadow: 0 0 0 0.025rem #b82519 !important;
  border-color: #b82519 !important;
}

.react-multi-email-tag-error {
  background-color: #b82519 !important;
  color: #ffffff !important;
  max-width: initial !important;
}

.custom-tag.in-active-tag {
  background: #eaeaea;
  color: #7d7d7d;
  z-index: 9;
}

.dropdown-menu-right {
  z-index: 9999 !important;
}

.card-dd-open {
  z-index: 999;
}

.react-multi-email-error-message {
  width: 100%;
  min-height: 80px !important;
  min-height: 80px !important;
  height: 80px !important;
  text-align: right;
  padding-right: 5px;
}

.react-multi-email-error-message-test {
  width: 100%;
  min-height: 40px !important;
  height: 40px !important;
  text-align: right;
  padding-right: 5px;
}

/* current css */
.show-dropdown {
  z-index: 9999999 !important;
}

.show-dropdown .footer-dropdowns .dropdown-menu {
  display: block !important;
}

.datepicker--cells {
  display: flex;
  flex-wrap: wrap;
}

.datepicker--cell {
  border-radius: 0.25rem;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 2rem;
  z-index: 1;
}

.datepicker--cell.-focus- {
  background: #f0f0f0;
}

.datepicker--cell.-current- {
  color: #4eb5e6;
}

.datepicker--cell.-current-.-focus- {
  color: #4a4a4a;
}

.datepicker--cell.-current-.-in-range- {
  color: #4eb5e6;
}

.datepicker--cell.-in-range- {
  background: rgba(92, 196, 239, 0.1);
  color: #4a4a4a;
  border-radius: 0;
}

.datepicker--cell.-in-range-.-focus- {
  background-color: rgba(92, 196, 239, 0.2);
}

.datepicker--cell.-disabled- {
  cursor: default;
  color: #aeaeae;
}

.datepicker--cell.-disabled-.-focus- {
  color: #aeaeae;
}

.datepicker--cell.-disabled-.-in-range- {
  color: #a1a1a1;
}

.datepicker--cell.-disabled-.-current-.-focus- {
  color: #aeaeae;
}

.datepicker--cell.-range-from- {
  border: 0.0625rem solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 0.25rem 0 0 0.25rem;
}

.datepicker--cell.-range-to- {
  border: 0.0625rem solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 0 0.25rem 0.25rem 0;
}

.datepicker--cell.-range-from-.-range-to- {
  border-radius: 0.25rem;
}

.datepicker--cell.-selected- {
  color: var(--theme-color-light);
  border: none;
  background: #5cc4ef;
}

.datepicker--cell.-selected-.-current- {
  color: var(--theme-color-light);
  background: #5cc4ef;
}

.datepicker--cell.-selected-.-focus- {
  background: #45bced;
}

.datepicker--cell:empty {
  cursor: default;
}

.datepicker--days-names {
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem 0 0.1875rem;
}

.datepicker--day-name {
  color: #ff9a19;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
}

.datepicker--cell-day {
  width: 14.28571429%;
}

.datepicker--cells-months {
  height: 10.625rem;
}

.datepicker--cell-month {
  width: 33.33%;
  height: 25%;
}

.datepicker--cells-years,
.datepicker--years {
  height: 10.625rem;
}

.datepicker--cell-year {
  width: 25%;
  height: 33.33%;
}

.datepickers-container {
  position: absolute;
  left: 0;
  top: 0;
}

@media print {

  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }

  .datepickers-container {
    display: none;
  }
}

.datepicker {
  background: var(--theme-color-search);
  border: 0.0625rem solid #dbdbdb;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-sizing: content-box;
  font-family: Tahoma, sans-serif;
  font-size: 0.875rem;
  color: #4a4a4a;
  width: 15.625rem;
  position: absolute;
  left: -6250rem;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s;
  z-index: 100;
}

.datepicker.-from-top- {
  transform: translateY(-0.5rem);
}

.datepicker.-from-right- {
  transform: translateX(0.5rem);
}

.datepicker.-from-bottom- {
  transform: translateY(0.5rem);
}

.datepicker.-from-left- {
  transform: translateX(-0.5rem);
}

.datepicker.active {
  opacity: 1;
  transform: translate(0);
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s;
}

.datepicker.a-datepicker {
  overflow: hidden;
}

.datepicker-inline .datepicker {
  border-color: #d7d7d7;
  box-shadow: none;
  position: static;
  left: auto;
  right: auto;
  opacity: 1;
  transform: none;
}

.datepicker-inline .datepicker--pointer {
  display: none;
}

.datepicker--content {
  box-sizing: content-box;
  padding: 0.25rem;
}

.-only-timepicker- .datepicker--content {
  display: none;
}

.datepicker--pointer {
  position: absolute;
  background: var(--theme-color-light);
  border-top: 0.0625rem solid #dbdbdb;
  border-right: 0.0625rem solid #dbdbdb;
  width: 0.625rem;
  height: 0.625rem;
  z-index: -1;
}

.-top-center- .datepicker--pointer,
.-top-left- .datepicker--pointer,
.-top-right- .datepicker--pointer {
  top: calc(100% - -0.6875rem);
  transform: rotate(135deg);
}

.-right-bottom- .datepicker--pointer,
.-right-center- .datepicker--pointer,
.-right-top- .datepicker--pointer {
  right: calc(100% - -0.6875rem);
  transform: rotate(225deg);
}

.-bottom-center- .datepicker--pointer,
.-bottom-left- .datepicker--pointer,
.-bottom-right- .datepicker--pointer {
  bottom: calc(100% - -0.6875rem);
  transform: rotate(315deg);
}

.-left-bottom- .datepicker--pointer,
.-left-center- .datepicker--pointer,
.-left-top- .datepicker--pointer {
  left: calc(100% - -0.6875rem);
  transform: rotate(45deg);
}

.-bottom-left- .datepicker--pointer,
.-top-left- .datepicker--pointer {
  left: 0.625rem;
}

.-bottom-right- .datepicker--pointer,
.-top-right- .datepicker--pointer {
  right: 0.625rem;
}

.-bottom-center- .datepicker--pointer,
.-top-center- .datepicker--pointer {
  left: calc(50% - 0.625rem / 2);
}

.-left-top- .datepicker--pointer,
.-right-top- .datepicker--pointer {
  top: 0.625rem;
}

.-left-bottom- .datepicker--pointer,
.-right-bottom- .datepicker--pointer {
  bottom: 0.625rem;
}

.-left-center- .datepicker--pointer,
.-right-center- .datepicker--pointer {
  top: calc(50% - 0.625rem / 2);
}

.datepicker--body {
  display: none;
}

.datepicker--body.active {
  display: block;
}

.datepicker--nav {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.0625rem solid #efefef;
  min-height: 2rem;
  padding: 0.25rem;
}

.-only-timepicker- .datepicker--nav {
  display: none;
}

.datepicker--nav-action,
.datepicker--nav-title {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.datepicker--nav-action {
  width: 2rem;
  border-radius: 0.25rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker--nav-action:hover {
  background: #f0f0f0;
}

.datepicker--nav-action.-disabled- {
  visibility: hidden;
}

.datepicker--nav-action svg {
  width: 2rem;
  height: 2rem;
}

.datepicker--nav-action path {
  fill: none;
  stroke: #9c9c9c;
  stroke-width: 0.125rem;
}

.datepicker--nav-title {
  border-radius: 0.25rem;
  padding: 0 0.5rem;
}

.datepicker--nav-title .appkiticon {
  font-style: normal;
  color: #9c9c9c;
  margin-left: 0.3125rem;
}

.datepicker--nav-title:hover {
  background: #f0f0f0;
}

.datepicker--nav-title.-disabled- {
  cursor: default;
  background: 0 0;
}

.datepicker--buttons {
  display: flex;
  padding: 0.25rem;
  border-top: 0.0625rem solid #efefef;
}

.datepicker--button {
  color: #4eb5e6;
  cursor: pointer;
  border-radius: 0.25rem;
  flex: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
}

.datepicker--button:hover {
  color: #4a4a4a;
  background: #f0f0f0;
}

.datepicker--cell-day.-other-month-,
.datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.datepicker--cell-day.-other-month-:hover,
.datepicker--cell-year.-other-decade-:hover {
  color: #c5c4c4;
}

.-disabled-.-focus-.datepicker--cell-day.-other-month-,
.-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.-selected-.datepicker--cell-day.-other-month-,
.-selected-.datepicker--cell-year.-other-decade- {
  color: var(--theme-color-light);
  background: #a2ddf6;
}

.-selected-.-focus-.datepicker--cell-day.-other-month-,
.-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4;
}

.-in-range-.datepicker--cell-day.-other-month-,
.-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #ccc;
}

.-in-range-.-focus-.datepicker--cell-day.-other-month-,
.-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2);
}

.datepicker--cell-day.-other-month-:empty,
.datepicker--cell-year.-other-decade-:empty {
  background: 0 0;
  border: none;
}

.datepicker--time {
  border-top: 0.0625rem solid #efefef;
  display: flex;
  align-items: center;
  padding: 0.25rem;
  position: relative;
}

.datepicker--time.-am-pm- .datepicker--time-sliders {
  flex: 0 1 8.625rem;
  max-width: 8.625rem;
}

.-only-timepicker- .datepicker--time {
  border-top: none;
}

.datepicker--time-sliders {
  flex: 0 1 9.5625rem;
  margin-right: 0.625rem;
  max-width: 9.5625rem;
}

.datepicker--time-label {
  display: none;
  font-size: 0.75rem;
}

.datepicker--time-current {
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 0.875rem;
  text-align: center;
  margin: 0 0 0 0.625rem;
}

.datepicker--time-current-colon {
  margin: 0 0.125rem 0.1875rem;
  line-height: 1;
}

.datepicker--time-current-hours,
.datepicker--time-current-minutes {
  line-height: 1;
  font-size: 1.1875rem;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  position: relative;
  z-index: 1;
}

.datepicker--time-current-hours:after,
.datepicker--time-current-minutes:after {
  content: "";
  background: #f0f0f0;
  border-radius: 0.25rem;
  position: absolute;
  left: -0.125rem;
  top: -0.1875rem;
  right: -0.125rem;
  bottom: -0.125rem;
  z-index: -1;
  opacity: 0;
}

.datepicker--time-current-hours.-focus-:after,
.datepicker--time-current-minutes.-focus-:after {
  opacity: 1;
}

.datepicker--time-current-ampm {
  text-transform: uppercase;
  align-self: flex-end;
  color: #9c9c9c;
  margin-left: 0.375rem;
  font-size: 0.6875rem;
  margin-bottom: 0.0625rem;
}

.datepicker--time-row {
  display: flex;
  align-items: center;
  font-size: 0.6875rem;
  height: 1.0625rem;
  background: linear-gradient(to right, #dedede, #dedede) left 50%/100% 0.0625rem no-repeat;
}

.datepicker--time-row:first-child {
  margin-bottom: 0.25rem;
}

.datepicker--time-row input[type="range"] {
  background: 0 0;
  cursor: pointer;
  flex: 1;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
}

.datepicker--time-row input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-sizing: border-box;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 0.1875rem;
  border: 0.0625rem solid #dedede;
  background: var(--theme-color-light);
  cursor: pointer;
  transition: background 0.2s;
  margin-top: -0.375rem;
}

.datepicker--time-row input[type="range"]::-ms-tooltip {
  display: none;
}

.datepicker--time-row input[type="range"]:hover::-webkit-slider-thumb {
  border-color: #b8b8b8;
}

.datepicker--time-row input[type="range"]:hover::-moz-range-thumb {
  border-color: #b8b8b8;
}

.datepicker--time-row input[type="range"]:hover::-ms-thumb {
  border-color: #b8b8b8;
}

.datepicker--time-row input[type="range"]:focus {
  outline: 0;
}

.datepicker--time-row input[type="range"]:focus::-webkit-slider-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef;
}

.datepicker--time-row input[type="range"]:focus::-moz-range-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef;
}

.datepicker--time-row input[type="range"]:focus::-ms-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef;
}

.datepicker--time-row input[type="range"]::-moz-range-thumb {
  box-sizing: border-box;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 0.1875rem;
  border: 0.0625rem solid #dedede;
  background: var(--theme-color-light);
  cursor: pointer;
  transition: background 0.2s;
}

.datepicker--time-row input[type="range"]::-ms-thumb {
  box-sizing: border-box;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 0.1875rem;
  border: 0.0625rem solid #dedede;
  background: var(--theme-color-light);
  cursor: pointer;
  transition: background 0.2s;
}

.datepicker--time-row input[type="range"]::-webkit-slider-runnable-track {
  border: none;
  height: 0.0625rem;
  cursor: pointer;
  color: transparent;
  background: 0 0;
}

.datepicker--time-row input[type="range"]::-moz-range-track {
  border: none;
  height: 0.0625rem;
  cursor: pointer;
  color: transparent;
  background: 0 0;
}

.datepicker--time-row input[type="range"]::-ms-track {
  border: none;
  height: 0.0625rem;
  cursor: pointer;
  color: transparent;
  background: 0 0;
}

.datepicker--time-row input[type="range"]::-ms-fill-lower {
  background: 0 0;
}

.datepicker--time-row input[type="range"]::-ms-fill-upper {
  background: 0 0;
}

.datepicker--time-row span {
  padding: 0 0.75rem;
}

.datepicker--time-icon {
  color: #9c9c9c;
  border: 0.0625rem solid;
  border-radius: 50%;
  font-size: 1rem;
  position: relative;
  margin: 0 0.3125rem -0.0625rem 0;
  width: 1em;
  height: 1em;
}

.datepicker--time-icon:after,
.datepicker--time-icon:before {
  content: "";
  background: currentColor;
  position: absolute;
}

.datepicker--time-icon:after {
  height: 0.4em;
  width: 0.0625rem;
  left: calc(50% - 1px);
  top: calc(50% + 1px);
  transform: translateY(-100%);
}

.datepicker--time-icon:before {
  width: 0.4em;
  height: 0.0625rem;
  top: calc(50% + 1px);
  left: calc(50% - 1px);
}

.datepicker-time {
  width: 11.375rem;
  height: 6.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.datepicker-time .datepicker-time-choose {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.datepicker-time .datepicker-time-choose.hidden {
  display: none;
}

.datepicker-time .datepicker-time-choose .date-time {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 0;
  margin: 0 0.625rem;
  color: var(--theme-color-font);
}

.datepicker-time .datepicker-time-choose .date-time:first-child {
  margin-left: 1.25rem;
}

.datepicker-time .datepicker-time-choose .date-time:last-child {
  margin-right: 1.25rem;
}

.datepicker-time .datepicker-time-choose .appkiticon {
  cursor: pointer;
  font-size: 0.5rem;
  width: 0.5rem;
}

.datepicker-time .datepicker-time-choose .time-value {
  width: 2.125rem;
  height: 2.125rem;
  background: var(--theme-color-dptime);
  border-radius: 0.125rem;
  color: var(--theme-color-font);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.datepicker-time .datepicker-time-hours,
.datepicker-time .datepicker-time-minutes {
  width: 100%;
  padding: 1.25rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  color: var(--theme-color-font);
}

.datepicker-time .datepicker-time-hours.hidden,
.datepicker-time .datepicker-time-minutes.hidden {
  display: none;
}

.datepicker-time .datepicker-time-hours .hour-num,
.datepicker-time .datepicker-time-hours .minute-num,
.datepicker-time .datepicker-time-minutes .hour-num,
.datepicker-time .datepicker-time-minutes .minute-num {
  width: 2.1875rem;
  height: 2.1875rem;
  text-align: center;
  line-height: 2.1875rem;
  cursor: pointer;
}

.datepicker-time .datepicker-time-hours .hour-num:hover,
.datepicker-time .datepicker-time-hours .minute-num:hover,
.datepicker-time .datepicker-time-minutes .hour-num:hover,
.datepicker-time .datepicker-time-minutes .minute-num:hover {
  background-color: var(--theme-color-dptime);
}

.datepicker-time .datepicker-time-minutes {
  width: 100%;
  display: flex;
}

.datepicker-time .datepicker-time-minutes.hidden {
  display: none;
}

.a-datepicker.air-picker {
  width: 13.125rem;
  border-radius: 0.3125rem;
}

.a-datepicker.air-picker.a-lg {
  width: 21.875rem;
  border-radius: 0.625rem;
}

.a-datepicker.air-picker.-only-timepicker- {
  width: 11.375rem;
  border-radius: 0.3125rem;
}

.a-datepicker .air-picker {
  width: 13.125rem;
  border-radius: 0.3125rem;
}

.a-datepicker.a-lg .air-picker {
  width: 21.875rem;
  border-radius: 0.625rem;
}

.a-datepicker.time-picker .air-picker,
.a-datepicker.time-picker.air-picker {
  width: 24.5rem;
}

.a-datepicker.time-picker .air-picker.-only-timepicker-,
.a-datepicker.time-picker.air-picker.-only-timepicker- {
  width: 11.375rem;
}

.a-datepicker.time-picker .air-picker.-only-timepicker- .datepicker-date,
.a-datepicker.time-picker.air-picker.-only-timepicker- .datepicker-date {
  display: none;
}

.a-datepicker.time-picker .datepicker-date {
  width: 13.125rem;
}

.a-datepicker .air-picker,
.a-datepicker.air-picker {
  border: 0;
  box-shadow: 0 0.4375rem 1.125rem 0.0625rem rgba(0, 0, 0, 0.16);
  font-family: inherit;
}

.a-datepicker .air-picker .datepicker--nav,
.a-datepicker.air-picker .datepicker--nav {
  padding: 0 0.625rem;
  border: 0;
  height: 2.125rem;
  align-items: center;
}

.a-datepicker .air-picker .datepicker--nav-title,
.a-datepicker.air-picker .datepicker--nav-title {
  font-size: 0.875rem;
  color: var(--theme-color-font);
  font-weight: 500;
}

.a-datepicker .air-picker .datepicker--nav-title:hover,
.a-datepicker.air-picker .datepicker--nav-title:hover {
  background-color: unset;
}

.a-datepicker .air-picker .datepicker--nav-action,
.a-datepicker.air-picker .datepicker--nav-action {
  width: 1rem;
  height: 1rem;
  color: var(--theme-color-font);
  font-size: 0.625rem;
}

.a-datepicker .air-picker .datepicker--nav-action:hover,
.a-datepicker.air-picker .datepicker--nav-action:hover {
  background-color: unset;
}

.a-datepicker .air-picker .datepicker--nav-action.-disabled-,
.a-datepicker.air-picker .datepicker--nav-action.-disabled- {
  visibility: visible;
  cursor: not-allowed;
}

.a-datepicker .air-picker .datepicker--nav-action.-disabled- .appkiticon,
.a-datepicker.air-picker .datepicker--nav-action.-disabled- .appkiticon {
  cursor: not-allowed;
}

.a-datepicker .air-picker .datepicker--content,
.a-datepicker.air-picker .datepicker--content {
  padding: 0;
}

.a-datepicker .air-picker .datepicker--cells-months,
.a-datepicker .air-picker .datepicker--cells-years,
.a-datepicker .air-picker .datepicker--years,
.a-datepicker.air-picker .datepicker--cells-months,
.a-datepicker.air-picker .datepicker--cells-years,
.a-datepicker.air-picker .datepicker--years {
  height: auto;
}

.a-datepicker .air-picker .datepicker--day-name,
.a-datepicker.air-picker .datepicker--day-name {
  color: var(--theme-color-font);
  font-weight: 500;
  height: 1.875rem;
  font-size: 0.75rem;
}

.a-datepicker .air-picker .datepicker--days-names,
.a-datepicker.air-picker .datepicker--days-names {
  margin: 0;
}

.a-datepicker .air-picker .datepicker--cell,
.a-datepicker.air-picker .datepicker--cell {
  height: 1.875rem;
  border-radius: 0;
  border: 0;
  font-size: 0.75rem;
  color: var(--theme-color-font);
}

.a-datepicker .air-picker .datepicker--cell[tabindex]:focus,
.a-datepicker.air-picker .datepicker--cell[tabindex]:focus {
  z-index: 99;
}

.a-datepicker .air-picker .datepicker--cell.datepicker--cell-month,
.a-datepicker.air-picker .datepicker--cell.datepicker--cell-month {
  height: 2.8125rem;
}

.a-datepicker .air-picker .datepicker--cell.datepicker--cell-year,
.a-datepicker.air-picker .datepicker--cell.datepicker--cell-year {
  height: 3.75rem;
}

.a-datepicker .air-picker .datepicker--cell.-focus-,
.a-datepicker.air-picker .datepicker--cell.-focus- {
  background-color: var(--theme-color-dprange);
  color: var(--theme-color-font);
}

.a-datepicker .air-picker .datepicker--cell.-disabled-,
.a-datepicker .air-picker .datepicker--cell.-other-month-,
.a-datepicker.air-picker .datepicker--cell.-disabled-,
.a-datepicker.air-picker .datepicker--cell.-other-month- {
  background-color: var(--theme-color-dpother);
  color: var(--theme-color-font);
  cursor: not-allowed;
}

.a-datepicker .air-picker .datepicker--cell.-other-month-.-selected-,
.a-datepicker.air-picker .datepicker--cell.-other-month-.-selected- {
  border-radius: 0 !important;
}

.a-datepicker .air-picker .datepicker--cell:not(.-other-month-):not(.-disabled-).-current-,
.a-datepicker.air-picker .datepicker--cell:not(.-other-month-):not(.-disabled-).-current- {
  background-color: var(--theme-color-dptoday);
  color: var(--theme-color-dpcurrent);
  box-shadow: none;
}

.a-datepicker .air-picker .datepicker--cell:not(.-other-month-):not(.-disabled-).-current-.-in-range-:not(.-selected-),
.a-datepicker.air-picker .datepicker--cell:not(.-other-month-):not(.-disabled-).-current-.-in-range-:not(.-selected-) {
  color: var(--theme-color-font);
}

.a-datepicker .air-picker .datepicker--cell:not(.-other-month-):not(.-disabled-).-in-range-,
.a-datepicker.air-picker .datepicker--cell:not(.-other-month-):not(.-disabled-).-in-range- {
  background-color: var(--theme-color-dprange);
  color: var(--theme-color-font);
}

.a-datepicker .air-picker .datepicker--cell:not(.-other-month-):not(.-disabled-).-selected-,
.a-datepicker.air-picker .datepicker--cell:not(.-other-month-):not(.-disabled-).-selected- {
  background-color: var(--theme-color-control);
  color: var(--theme-color-purewhite);
  font-weight: 500;
}

.a-datepicker .air-picker .datepicker--cell:not(.-other-month-):not(.-disabled-).-range-from-:not(.-selected-),
.a-datepicker .air-picker .datepicker--cell:not(.-other-month-):not(.-disabled-).-range-to-:not(.-selected-),
.a-datepicker.air-picker .datepicker--cell:not(.-other-month-):not(.-disabled-).-range-from-:not(.-selected-),
.a-datepicker.air-picker .datepicker--cell:not(.-other-month-):not(.-disabled-).-range-to-:not(.-selected-) {
  background-color: var(--theme-color-dprange);
  color: var(--theme-color-font);
}

.a-datepicker.a-lg .air-picker .datepicker--content,
.a-datepicker.a-lg.air-picker .datepicker--content {
  padding-bottom: 0.625rem;
}

.a-datepicker.a-lg .air-picker .datepicker--day-name,
.a-datepicker.a-lg .air-picker .datepicker--nav,
.a-datepicker.a-lg.air-picker .datepicker--day-name,
.a-datepicker.a-lg.air-picker .datepicker--nav {
  height: 3.125rem;
}

.a-datepicker.a-lg .air-picker .datepicker--nav-title,
.a-datepicker.a-lg.air-picker .datepicker--nav-title {
  font-size: 1rem;
}

.a-datepicker.a-lg .air-picker .datepicker--nav-action,
.a-datepicker.a-lg.air-picker .datepicker--nav-action {
  font-size: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
}

.a-datepicker.a-lg .air-picker .datepicker--cell,
.a-datepicker.a-lg.air-picker .datepicker--cell {
  height: 2.75rem;
  margin: 0.1875rem 0;
  font-size: 1rem;
}

.a-datepicker.a-lg .air-picker .datepicker--cell.datepicker--cell-month,
.a-datepicker.a-lg.air-picker .datepicker--cell.datepicker--cell-month {
  height: 4.125rem;
}

.a-datepicker.a-lg .air-picker .datepicker--cell.datepicker--cell-year,
.a-datepicker.a-lg.air-picker .datepicker--cell.datepicker--cell-year {
  height: 5.5rem;
}

.a-datepicker.a-lg .air-picker .datepicker--cell.-selected-,
.a-datepicker.a-lg.air-picker .datepicker--cell.-selected- {
  border-radius: 0.3125rem;
}

.a-datepicker.a-lg .air-picker .datepicker--cell.-range-from-,
.a-datepicker.a-lg.air-picker .datepicker--cell.-range-from- {
  border-radius: 0.3125rem 0 0 0.3125rem;
}

.a-datepicker.a-lg .air-picker .datepicker--cell.-range-from-.-range-to-,
.a-datepicker.a-lg.air-picker .datepicker--cell.-range-from-.-range-to- {
  border-radius: 0.3125rem;
}

.a-datepicker.a-lg .air-picker .datepicker--cell.-range-to-,
.a-datepicker.a-lg.air-picker .datepicker--cell.-range-to- {
  border-radius: 0 0.3125rem 0.3125rem 0;
}

.a-datepicker.a-lg .air-picker .datepicker--cell.-range-to-.-range-from-,
.a-datepicker.a-lg.air-picker .datepicker--cell.-range-to-.-range-from- {
  border-radius: 0.3125rem;
}

.a-datepicker.a-title-left .datepicker--nav {
  position: relative;
}

.a-datepicker.a-title-left .datepicker--nav .datepicker--nav-action[data-action="prev"] {
  margin-left: auto;
}

.a-datepicker-input {
  display: block;
  width: 13.125rem;
}

.a-datepicker-input.a-lg {
  width: 21.875rem;
}

.a-datepicker-input .a-text-input.open {
  background-color: var(--theme-color-search);
  box-shadow: 0 0 0 0.125rem var(--theme-color-control);
  border: none;
}

.a-datepicker-input .a-icon {
  cursor: pointer;
  line-height: 1;
}

.a-input-with-icon.a-rt .a-text-input {
  cursor: pointer;
}

.a-datepicker-input .a-datepicker-input {
  width: 13.125rem;
}

.a-input-with-icon.a-rt .a-text-input {
  cursor: pointer;
}

.a-datepicker-input .a-datepicker-input.a-lg {
  width: 21.875rem;
}

.a-double-datepicker,
.a-double-datepicker.a-datepicker.air-picker {
  width: 26.875rem;
  display: inline-flex;
  box-shadow: 0 0.4375rem 1.125rem 0.0625rem rgba(0, 0, 0, 0.16);
  border-radius: 0.625rem;
  background-color: var(--theme-color-search);
}

.a-double-datepicker.a-datepicker.air-picker.a-lg,
.a-double-datepicker.a-lg {
  width: 44.125rem;
}

.a-double-datepicker.a-datepicker.air-picker.a-lg .a-datepicker:first-child,
.a-double-datepicker.a-lg .a-datepicker:first-child {
  margin-right: 0.375rem;
}

.a-double-datepicker .a-datepicker:first-child,
.a-double-datepicker.a-datepicker.air-picker .a-datepicker:first-child {
  margin-right: 0.625rem;
}

.a-double-datepicker .a-datepicker .air-picker,
.a-double-datepicker.a-datepicker.air-picker .a-datepicker .air-picker {
  box-shadow: none;
  border-radius: 0.625rem;
}

.fade.modal {
  opacity: 1 !important;
}

.calender {
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  width: 100%;
}

.menu-box {
  width: 12.625rem;
  flex: 1 1 auto;
  padding: 1rem 0.375rem 1rem 1.25rem;
}

.menu-box .title {
  font-size: 0.75rem;
  line-height: 1.58;
  color: var(--theme-color-font);
  font-weight: normal;
}

.menu-box .menu {
  margin-top: 1.25rem;
}

.menu-box .menu li {
  color: var(--theme-color-tabfont);
  font-size: 0.875rem;
  line-height: 1.64;
  font-weight: normal;
  margin: 0.625rem 0;
  cursor: pointer;
}

.menu-box .menu li:first-child {
  margin-top: 0;
}

.menu-box .menu li.active {
  color: var(--theme-color-control);
  font-weight: 500;
}

.w-606 {
  width: 34.875rem !important;
}

.a-datepicker.a-lg .air-picker .datepicker--cell,
.a-datepicker.a-lg.air-picker .datepicker--cell {
  height: 2rem !important;
}

.content-container .a-page-header .a-page-subtitle.calender-title {
  font-size: 14px;
  text-transform: unset;
}

.custom-stake-settings {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.calender-reset {
  color: var(--theme-color-control);
  font-size: 14px;

  i {
    transform: rotate(-90deg);
  }

  cursor: pointer;
}

.modal-dialog {
  max-width: 600px !important;
}

.a-modal-footer button {
  width: auto !important;
}

.a-font-sm {
  font-size: 0.75rem !important;
}

.a-list .a-list-item {
  padding: 0.625rem;
}

.a-font-xs {
  font-size: 0.625rem !important;
}

.stakeholder-modal-filters.custom-tags-modal .modal-dialog .modal-content .r-s-body {
  min-height: 400px;
}

.SumoSelect>.CaptionCont {
  border: none !important;
  background-color: var(--theme-color-input);

  :focus {
    background-color: #fff;
  }
}

.SumoSelect > .CaptionCont > span.placeholder{
  background: unset;
}

.a-btn.a-btn-primary:focus,
.a-btn.a-btn-secondary:focus {
  outline: none !important;
}

.a-modal-footer {
  justify-content: flex-end;
}

.react-multi-email>input {
  width: 100% !important;
}

.text-toggle {
  span.open-text-acc {
    display: block !important;
  }

  span.close-text-acc {
    display: none !important;
  }
}

.text-toggle.collapsed {
  span.open-text-acc {
    display: none !important;
  }

  span.close-text-acc {
    display: block !important;
  }
}

.text-toggle:not(.collapsed) {
  span.open-text-acc {
    display: block !important;
  }

  span.close-text-acc {
    display: none !important;
  }
}

.stakeholder-modal-filters .modal-dialog {
  max-width: 1160px !important;
  width: 96% !important;
}

table.filter-stakeholder-table thead {
  background-color: #e6e6e6;
}

table.filter-stakeholder-table thead tr td .form-group {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

table.filter-stakeholder-table thead tr td .form-group .appkiticon {
  margin-left: 5px;
  color: #c35a2c;
}

.table {
  --bs-table-bg: unset;
}

table.filter-stakeholder-table thead tr td {
  padding: 0.4875rem 1.25rem;
  vertical-align: middle;
}

.stakeholder-modal-filters .modal-dialog .modal-content {
  padding: 20px;
}

.stakeholder-modal-filters .modal-dialog .modal-content .r-s-body {
  min-height: 300px;
}

.text-muted {
  color: #777;
}

table.filter-stakeholder-table tbody tr td {
  border: none;
}

.stakeholder-modal-filters .modal-dialog .modal-content .a-modal-header .icon-wrapper {
  position: absolute !important;
  top: 10px;
  right: 10px;
}

.stakeholder-modal-filters .modal-dialog .modal-content .a-modal-header .icon-wrapper i {
  font-size: 10px;
}

.show-on-top {
  z-index: 9999;
}

.show-on-top .modal-dialog {
  max-width: 700px !important;
  width: 50% !important;
}

.disable-link {
  pointer-events: none;
}

.a-btn-primary {
  background-color: #eb590b !important;
}

.a-btn-primary:active,
.a-btn-primary.active {
  background-color: #c54a09 !important;
}

.SumoSelect {
  width: 100%;
}

.right-sidebar-wrap:after {
  content: "";
  z-index: 99;
  top: 82px;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  background: #000;
}

.cq-header-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dot {
  height: 25px;
  width: 25px;
  border: 1px solid #759e93;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
}

.thumb-up-circle-border {
  border: 1px solid #759e93;
}

.thumb-down-circle-border {
  border: 1px solid #f17b6f;
}

.thumb-skip-circle-border {
  border: 1px solid #7d7d82;
}

.selected-filter-wrap {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;

  .selected-filter-tags {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .selected-filter-label {
      color: #7d7d7d;
      font-size: 14px;
      margin-right: 8px;
      line-height: 40px;
    }

    .a-tag.a-tag-light {
      background-color: #d8d8d8;
      color: #2d2d2d;
    }

    .a-tag,
    .selected-filter-clear {
      margin: 0.3125rem;
    }

    .selected-filter-clear a {
      font-size: 13px;
      color: #eb590b;
      cursor: pointer;
    }
  }

  .selected-filter-result {
    color: #7d7d7d;
    font-size: 13px;
    line-height: 40px;
    min-width: 230px;
  }
}

.b-top1 {
  border-top: 1px solid #d8d8d8;
}

.custom-question-filter {
  .cq-filter-label {
    font-size: 14px;
    font-weight: 600;
    margin-left: 1.25rem;
    color: #2d2d2d;
  }

  .selected-filter-wrap {
    margin-bottom: 6px;
    padding: 0;
    padding-left: calc(20px - 0.3125rem);
    padding-right: calc(20px - 0.3125rem);
  }
}

.cq-bottom-tagline {
  font-size: 11px;
  font-weight: 500;
}

.cq-bottom-list {
  display: flex;
  align-items: flex-start;
  font-size: 12px;

  ul {
    padding-left: 8px;
    font-size: 11px;
    font-weight: 500;

    li {
      list-style: none;
    }
  }
}

.pending-question-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 12px 10px;
  background: #e0e0e0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .pq-heading-text {
    font-size: 14px;
    font-weight: 600;
  }

  .p-question-numbers {
    color: #7d7d7d;
    font-size: 13px;
    line-height: 20px;
  }
}

.pending-question-body {
  border: 1px solid #eee;
  padding: 0px 10px;
}

.pending-question-body .rs-question-wrap:last-child .rs-question-container {
  border-bottom: none;
}

.activation-wrap {
  flex: auto;
  width: 100%;
  color: #2d2d2d;
  padding: 1.875rem 0;
  padding-left: 75px;
  padding-right: 75px;

  .activation-inner {
    max-width: 600px;
    width: 100%;

    h5 {
      font-weight: 600;
    }

    p {
      font-size: 16px;

      a {
        color: #d04a02;
      }
    }
  }
}

.a-alert.activation-alert {
  margin: 0;
  width: 100%;
  max-width: 100%;
  border-radius: 0;
  left: 0;
  transform: none;

  .a-alert-content {
    justify-content: center;

    .a-content {
      color: #fff;
    }
  }
}

.a-alert.activation-alert.success {
  background-color: #22992e !important;
}

.alert-bs-5 {
  background: white;
  border: 1px solid;
  left: 50%;
  top: -360px;
  transform: translate(-50%, -50%);
  z-index: 10,
}

.a-tag.alert-tag {
  border-radius: 3px;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 15px;

  p {
    white-space: break-spaces;
    font-size: 12px;
    color: #2d2d2d;
    line-height: 13px;
    margin: 0;

    span {
      font-weight: 500;
    }
  }
}

.icon-fade {
  opacity: 0.5;
  cursor: not-allowed;
}

.activation-inner p {
  font-size: 13px !important;
  font-family: "PwC Helvetica Neue";
}

.activation-wrap .activation-inner h4 {
  font-size: 19px;
  font-weight: bold;
}

.activation-wrap .activation-inner .a-btn.bt-login {
  font-size: 13px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .activation-wrap .activation-inner .a-btn.bt-login {
    text-align: center;
    width: 100%;
    display: block;
  }

  .activation-inner p a.btn {
    padding: 0;
    min-height: auto;
  }

  .activation-inner p {
    font-size: 13px !important;
    text-align: center;
  }

  .account-activation-login.login-page {
    background: #ffffff;
  }

  .account-activation-login.login-page .left-side {
    display: block !important;
    height: 80px;
    width: 100%;

    .logo-box {
      display: none;
    }

    .notice-text h2 {
      font-size: 18px !important;
      margin: 15px 0 !important;
    }

    .notice-text h3 {
      display: none;
    }
  }

  .account-activation-login.login-page .right-side {
    height: auto;

    .content {
      min-height: auto;
      height: auto;
      flex-wrap: wrap;
      position: static;
    }
  }

  .account-activation-login.login-page .at-footer {
    left: 0;
    bottom: 0;
    padding: 0 22px;
  }

  .account-activation-login.login-page .at-footer p {
    text-align: left;
  }
}

.optional-badge {
  display: flex !important;
  flex-wrap: wrap;
  height: auto !important;
  max-width: 250px;
}

.optional-badge p {
  margin: 0 !important;
  font-size: 13px !important;
}

.hide-element {
  display: none;
}

.domain_setting_tooltip {
  height: 180px;
  width: 180px;
}

table.filter-stakeholder-table thead tr td.column {
  font-weight: bold;
}

table.filter-stakeholder-table thead {
  background-color: #E0E0E0;
  position: sticky;
  top: 0px;
  z-index: 2;
}

table.filter-stakeholder-table tbody tr:nth-child(even) {
  background: #F5F5F5;
}

.modal-content .modal-footer button {
  text-transform: uppercase;
}

table.filter-stakeholder-table tbody tr td .form-group {
  margin: 0;
}

.a-btn.a-btn-primary:disabled:not(.a-no-interaction) {
  opacity: var(--theme-color-disableopacity);
  cursor: not-allowed;
  background-color: var(--theme-color-btndisablebg) !important;
  color: var(--theme-color-btndisabletext) !important;
}

.custom-tag-group-tooltip {
  width: 215px;
  padding: 4px 8px !important;
}

.right-sidebar-wrap {
  position: fixed;
  z-index: 1049;
}

.cstm-dmn-icn-grp {
  display: flex;
  align-items: center;
  position: absolute;
  top: -4px;
  right: 0px;
}

.cstm-dmn-icn-grp .dropdown-icon {
  position: relative;
}

dev.cstm-dmn-icn-grp .dropdown-icon:nth-child(2) {
  top: 0px;
  margin-left: 5px;
}

.sgmnt-insufcnt-dta {
  border-top: 1px solid #d3d3d3;
}

.sgmnt-insufcnt-dta .some-insufficient-result-container {
  border: none;
  background-color: #dbdbdb;
}

.qstn-obj-cnt {
  display: flex;
}

.qstn-obj-cnt .question-number {
  position: absolute;
  right: 0px;
  top:13px;
}

.rs-question-container span.align-question {
  position: relative;
  top: 3px;
}

.mandatory-icon {
  font-size: 13px;
  color: red;
}

.hover-black-btn:hover svg path {
  fill: #2d2d2d;
}

.a-alert .warning-close-btn .appkiticon:before {
  margin: 3px 0 !important;
}

.a-icon {
  color: #9e9e9e !important;
}

button.pills-button {
  background: transparent;
}

button.pills-button i {
  margin-left: 5px !important;
}

.c-icon-size {
  font-size: 6px !important;
  vertical-align: middle;
  transform: translate(3px, 1px);
}

.btn-black.reset-button a {
  color: #2d2d2d
}

.btn-black.reset-button {
  font-weight: 400;
}

.black-notification-bar {
  color: #ffffff !important;
  background: #464646 !important
}

.black-notification-bar p {
  color: #ffffff !important;
  font-size: 11px;
  margin: -9px 15px -8px 0px !important;
}

.a-btn.a-btn-secondary.custom-primary {
  color: gray !important;
  border-color: gray !important;
  border: 2px solid;
  background: #ffffff !important
}

.a-btn.a-btn-secondary.custom-secondary {
  border-color: gray !important;
  color: #ffffff !important;
  border: 2px solid;
}

.a-btn.a-btn-secondary.custom-excel-export {
  color: #FFF !important;
  border-color: gray !important;
  border: 2px solid;
  background: #000 !important
}

.a-btn.a-btn-secondary.request-support {
  border-color:  #55514F !important;
  color: #000 !important;
  border: 1px solid;
  border-radius: 1px;
}

.a-btn.a-btn-secondary.custom-secondary:disabled {
  border-color: #ffffff !important;
  color: #ffffff !important
}

.btn-expand {
  padding-right: 40px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.sgmnt-grp p {
  font-size: 16px;
  font-weight: bold !important;
  margin: 10px 0;
}

.sgmnt-grp .badge-dropdown .dropdown-select {
  width: 100% !important;
}



.bg-transparent.a-btn.a-btn-secondary.custom-secondary:disabled {
  background-color: transparent !important;
}

.segmentGroupList {
  list-style: disc;
  font-size: 14px;
}

.segmentGroupList li {
  list-style: disc;
  font-size: 14px;
}

.acq-modal .show .dropdown-select {
  border: 2px solid #eb590b
}

.cc-group-number {
  color: #BCBCBC;
  font-size: 13px;
  font-weight: 600;
}

.cc-growth {
  display: flex;
  align-items: center;
  color: #828282;
  font-size: 14px;
}

.confidence-compare {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0px 10px;
  justify-content: center;
}

.project-percentage.cg-content {
  // min-width: 100px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.cgc-text {
  font-size: 16px;
  font-weight: 700;
  color: #7D7D7C;
}

.project-percentage.cg-content {
  .no-oc-data:before {
    width: 100% !important;
  }
}

.ccd-label-text {
  text-align: center;
  margin: 10px 0px;
  font-size: 14px;
  color: #7D7D7C;
  font-weight: 400;
  line-height: 18px;
}

.ccq-tag-number {
  color: #BCBCBC;
  font-size: 12px;
  font-weight: 500;
  padding-right: 5px;
}

.col-t1.confidence-compare-card {
  width: auto !important;
  min-width: 280px;

  .data-item-box.project-box {
    width: auto !important;
    min-width: 280px;
  }
}

.cc-badge-group {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.cct-list-item {
  display: flex;
  align-items: end;
  justify-content: stretch;
  margin-bottom: -8px;
}

span.txt-grey {
  font-size: 14px;
  font-weight: 600;
  color: #c2c2c7;
}

.ccti-element {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  height: 40px;
  align-items: center;
  margin-right: 2px;
  padding: 0px 15px;
}

.ccti-element.first-ccti {
  border: 1px solid #d9d9d9;
  font-size: 14px;
  color: #7d7d7d;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  justify-content: flex-start;
  min-width: 150px;
  max-width: 150px;
}

.ccti-element.center-element {
  background: #ccc;
  font-size: 14px;
  font-weight: 500;
  flex: 1;
  min-width: 80px;
  position: relative;
}

.ccti-element.center-element.tag-blue {
  .c-right-icon{
    color: #66a295;
  }
}

.ccti-element.center-element.tag-warning {
  .c-right-icon{
    color: #deb450;
  }
}

.ccti-element.center-element.tag-danger {
  .c-right-icon{
    color: #e23d2c;
  }
}

.cct-list-item .ccti-element:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ccti-element.center-element.green-element {
  color: #73A598;
  background: #E5EFED;
}

.ccti-element.center-element.yellow-element {
  color: #DBBA68;
  background: #FBE8B4;
}

.ccti-element.center-element.red-element {
  color: #B64B2A;
  background: #E7C7BD;
}

.cct-list .cct-list-item:first-child .ccti-element.center-element::after {
  position: absolute;
  top: -22px;
  font-size: 14px;
  font-weight: 600;
  color: #c2c2c7;
}

.cc-timeline-wrap .cct-list:not(:first-child) .ccti-element.center-element::after {
  content: '';
  display: none;
}

.card-body.card-open.text-toggle[aria-expanded="false"] .nested-card-title:after {
  transform: rotate(180deg);
}

.margin-top-filter-dropdown {
  margin-top: 12px !important;
}

.primary-outline {
  background: none !important;
  border: 3px solid #EB590B;

  i::before {
    color: #EB590B;
  }
}

.primary-outline:hover {
  background: #EB590B !important;

  i::before {
    color: #ffffff;
  }
}

.cstm-agd-info ul li {
  padding-left: 10px !important;
}

ul.aged-rem-q-header {
  padding: 0 20px;
}

ul.aged-rem-q-header ul li {
  font-size: 13px;
}

ul.aged-rem-q-header li {
  font-size: 13px;
  padding-left: 30px;
  position: relative;
}

ul.aged-rem-q-header li:before {
  content: "";
  background: #222;
  width: 5px;
  height: 5px;
  position: absolute;
  left: 0;
  border-radius: 50%;
  top: 5px;
}

.p-s-tag {
  font-size: 13px !important;
}

.drafted-projects-page {
  margin-top: 25px;
}


//welcome message wrapper
.welcome-message-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;

  .welcome {
    color: var(--theme-color-d25646);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    cursor: pointer;

    text {
      margin-left: 0.188rem;
      text-transform: capitalize;
    }
  }
}

//modal pop-up inner content
.preview-modal {
  .modal-dialog {
    max-width: 72.5rem !important;
    width: 50% !important;
  }

  &.custom-tags-modal {
    .modal-dialog {
      .modal-content {
        .r-s-body {
          min-height: 31.25rem;
        }
      }
    }
  }
}

.qr-code {
  max-width: 6.25rem;

  .cls-1 {
    fill: var(--theme-color-light);
  }
}

.preview-message {
  p {
    margin: 0.5rem 0;
    font-size: 0.9rem;
  }
}

.a-pwc-border-box {
  border: 0.063rem solid var(--theme-color-content);
}

.bg-light-gray {
  background-color: var(--theme-color-content);
  min-height: 2.25rem;
}

.bg-bdgsecondary {
  background-color: var(--theme-color-bdgsecondary);
}





.text-info-sm {
  color: var(--theme-color-arrow);
  margin: 0.313rem 0 0.625rem 0;
  text-align: center;
  font-size: 0.8rem;
}

.text-head {
  color: rgba(33, 33, 33, 1);
  font-size: 28px;
  letter-spacing: -0.04em;
  font-family: "Segoe UI", "-apple-system", "BlinkMacSystemFont", "Roboto", "Arial", sans-serif;
}

.info-gray-headline {
  color: var(--theme-color-samplecodeicon);
}

.dropdown-menu-stakeholder {
  transform: translate3d(-29px, 4px, 0px) !important;
  // transform: translate3d(-138px, 14px, 0px) !important;
  z-index: 999999 !important;
  width: auto !important;
}

.close-icon-search {
  position: absolute;
  right: 10px !important;
  left: unset !important;
  transform: translateY(0%) !important;
  cursor: pointer;
  font-size: larger !important;
}

.more-horiz-icon {
  z-index: auto !important;
}

.segment-dd-label_tooltip {
  width: auto;
}

.all-projects-label-tooltip {
  width: auto;
}

.a-btn.a-btn-lg.new_cus_filter_btn:disabled {
  background: #e8e8e8 !important;
  color: var(--theme-color-btndisabletext) !important;
  pointer-events: none;
}


.sgmnt-grp.selectedSegmentGroupValue::-webkit-input-placeholder {
  color: lightgray !important;
}

.desc_icons {
  transform: scale(1.5);
}

.custom-select-sgmnt-dropdown .badge-dropdown .dropdown-select {
  width: 210px !important;
}

.dropdown-select.lightgrey .cstm-sgmntdd {
  color: #b1b1b1 !important;
font-family: "PwC Helvetica Neue" !important;
  span {
    font-size: 16px;
    color: #7d7d7d !important;
  }
}

.cstm-slct-drp-dwn .dropdown-select-ul li .badge-label {
  .circle-badge {
    margin-right: 2px;
  }

  span {
    font-size: 16px;
    font-weight: 400;
    color: #7d7d7d !important;
  }
}

.badge-dropdown .dropdown-item-c span {
  font-size: 12px;
  color: #7d7d7d;
  font-weight: 300;
  padding-left: 0 !important;
}

.square-btn:disabled {
  opacity: var(--theme-color-disableopacity) !important;
  cursor: not-allowed !important;
  background-color: var(--theme-color-btndisablebg) !important;
  color: var(--theme-color-btndisabletext) !important;
  border: 3px solid var(--theme-color-btndisablebg) !important;

  i::before {
    color: #989898;
  }
}

i.project-filter-margin {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.new_cus_filter_btn {
  padding-left: 10px !important;
  padding-right: 10px !important;
  border: 1px solid #FA5927;
  margin-top: 17px;
  max-height: 20px !important;
  margin-bottom: 17px;

  span.icon-filter-outline {
    margin-right: 5px;
  }
}

.mr-5 {
  margin-right: 40px !important;
}

.ml-5 {
  margin-left: 40px !important;
}

.custom-top-title button {
  background-color: #696A67 !important;
}

.custom-top-title p.text-lt {
  font-size: 13px;
  margin-right: 6px !important;
}

.custom-top-title .border-left {
  padding-left: 6px;
  margin-left: 2px !important;
  font-weight: normal !important;
}

.cs-timepicker .sumo-icon-container>span {
  position: absolute;
  left: -17px;
  z-index: 1;
  top: 14px;
}

p.adhoc {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin: 0.7rem !important;
}

.responses-date-info {
  max-width: 160px;
  border: 1px solid #ddd !important;
  margin-bottom: 20px;
}

label.a-text-label {
  font-size: 14px;
  font-weight: 500;
}

.col-t1.confidence-compare-card.fix-width {
  width: 500px !important;
  min-width: 280px;
}

.react-multi-email [data-tag] {
  max-width: unset !important;
}

.sgm-custom-width {
  max-width: 100% !important;
}

.fields-wrap {
  margin-bottom: 10px;
}

.no-match {
  font-size: 14px;
}

.launch-message-tooltip {
  font-weight: 400;
  color: #fff;
  background-color: #333;
}

#launch-label-icon {
  opacity: 1;
}

.dropdown-box {
  border: 1px solid #ccc;
  border-radius: 2px;
  position: absolute;
  background: #fff;
  width: 100%;
  z-index: 1;
  // height: 120px;
  overflow-y: scroll;
  max-height: 120px;

  li {
    border-bottom: 1px solid #ccc;

    &:last-child {
      border-bottom: unset;
    }

    p {
      margin: 0;
      padding: 10px;
      cursor: pointer;
      font-size: 14px;

      span.link {
        color: #FA5927;
      }
    }

    &:hover {
      background: var(--theme-color-btnsechover);
    }
  }
}

.prevent_user_tooltip {
  height: 130px;
  width: 135px;
  padding: 10px !important;
  font-size: 13px !important;
}

.result_tooltip {
  height: 220px;
  width: 280px;
  padding: 10px !important;
  background-color: #000;
  text-align: left;

  span {
    margin-bottom: 10px;
    font-size: 11.2px;
    color: #fff;
    display: block;
  }

  ul {
    padding-left: 30px;
    margin-bottom: 8px;

    li {
      list-style-type: disc;
      margin-bottom: 2px;
      font-size: 11.2px;
      color: #fff;
    }
  }
}
.result_tip{
  height: 150px;
  width: 180px;
  padding: 10px !important;
  background-color: #000;
  text-align: center;

  span {
    margin-bottom: 5px;
    font-size: 11.2px;
    color: #fff;
    display: block;
  }
}

#menu-icon-index {
  z-index: 1;
}

.user-guidance {
  margin-top: 1px;
  position: relative;
  bottom: -3px;

  i {
    font-size: 14px !important;
    color: #bababa !important;
    cursor: pointer;
    margin-left: 5px;
  }
}
.user-guid {
  margin-top: -3px;
  position: relative;
  bottom: 1px;

  i {
    font-size: 12px !important;
    color: #bababa !important;
    cursor: pointer;
    margin-left: 5px;
  }
}

.material-icons.md-24.md-dark.search {
  font-size: 12px !important;
}

.report-btn-tooltip {
  width: auto;
  z-index: 10 !important;
}

.custom-question-container span.fq-font {
  font-style: italic;
}

.next-cycle-tooltip {
  height: 90px;
  width: 100px;
  padding: 5px !important;
  font-size: 13px !important;
}

.create-seg-tooltip {
  height: 120px;
  width: 220px;
  padding: 5px !important;
}

.feedback-form {
  position: fixed;
  right: 0;
  bottom: 40px;
  z-index: 9;
}

.feedback-form .pill {
  background: #7A7A7A;
  width: 50px;
  height: 50px;
  border-radius: 30px 0 0 30px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  margin-left: auto;
}

.feedback-form .icon.open svg {
  width: 26px;
  height: 26px;
}

.feedback-form .open span {
  color: #fff;
  visibility: hidden;
}

.feedback-form .open.pill:hover {
  width: 170px;
}

.feedback-form .open:hover svg {
  margin-right: 10px;
}

.feedback-form .open:hover span {
  visibility: visible;
}

.feedback-form .cancel.pill {
  width: 130px;
}

.feedback-form .options {
  background: #fff;
  padding: 10px;
  margin-right: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0728256);
  margin-bottom: 10px;
}

.feedback-form .options button {
  margin: 0;
  display: block;
}

.feedback-form .cancel svg {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.feedback-form .cancel span {
  color: #fff;
}

.form-fields {
  background: #fff;
  padding: 10px;
  margin-right: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0728256);
  width: 290px;
  margin-bottom: 10px;
}

.form-fields span.heading {
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}

.form-fields textarea {
  height: unset !important;
  resize: none;
}

.form-fields .form-group {
  margin-bottom: 6px;
}

.form-fields .form-group span {
  display: block;
  text-align: right;
  font-size: 12px;
  color: #767676;
}

.success-msg {
  background: #fff;
  padding: 30px 16px;
  margin-right: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0728256);
  width: 290px;
  margin-bottom: 10px;
  text-align: center;
  position: relative;
}

.success-msg img {
  width: 80px;
  margin-bottom: 20px;
}

.success-msg h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 14px;
}

.success-msg p {
  margin: 0;
  font-size: 14px;
  color: #000;
}

.success-msg .close-icon span {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}

#red {
  color: red;
}

.form-group div.feedback-error {
  float: left;
  color: red;
  font-size: 0.75rem;
  margin-bottom: 4px;
}

.download-btn-spinner {
  .a-alert-badge {
    height: unset !important;
  }
  .a-btn {
    padding: 0 !important;
  }
  .a-alert-content {
    padding: 4px 10px !important;
    .a-content {
      padding-right: 0px !important;
    }
  }
} 
.show-title {
  position: fixed;
  bottom: 0;
  background : white;
  padding: 15px 22px;
  width: 400px;
  right: 0;
  z-index: 10;
}

.archive_lck_tooltip {
  width: 217px;
  height: 160px;
  padding: 10px !important;
  font-size: 13px !important;
}

.archive_lock_pre_tooltip {
  width: auto;
  height: auto;
  padding: 10px !important;
  font-size: 13px !important;
}

.ovrall-talk {
  font-size: 0.9rem;
  a {
    cursor: pointer;
    color: #eb590b !important;
  }
}

.fix-width{
  width: 2.325rem;
}

.selected-filter-wrap{
  .segmentGroupList{
    .selected-filter-tag{
      margin-right: 4px;
      margin-bottom: 0px;
  } 
 }
}

.multi {
  width: 32.5rem !important;
  height: 7rem !important;
} 

.upload-success  {
  width: 32.5rem !important;
  height: 2rem !important;
}