.custom-tooltip {
    position: relative;
  }
  
  .custom-tooltip .tooltip-text {
    visibility: hidden;
    background: #222;
    color: #fff;
    left: -90px;
    padding: 5px 15px;
    position: absolute;
    z-index: 98;
    width: auto;
    min-width: 120px;
    height: 100%;
    top: 0;
    display: inline-table;

    font-family: "PwC Helvetica Neue";
    border: 1px solid transparent;
    font-size: 13px;
    border-radius: 3px;
    font-weight: 400;
}
  
  .custom-tooltip .tooltip-text::before {
    border: solid;
    border-color: #333 transparent;
    border-width: 6px 6px 0px 6px;
    top: 15px;
    content: "";
    right: -9px;
    position: absolute;
    z-index: 99;
    transform: rotate(-90deg);
}
  
  .custom-tooltip:hover .tooltip-text {
    visibility: visible;
  }

  .tag-hover-menu span.custom-tooltip {
    /* Temporary commented this line for
      *  Product Backlog Item 10897: Hide the option for autogenerated questions
      height: 26px;
    */
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
}

.archive-notification-text strong {
  color: #000;
  font-family: "PwC Helvetica Neue" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.download-dropdown .dropdown-item:hover {
background-color: #F9EBE9;
}