/*textbox-with icons*/

.d-inline-block.text-icon {
    position: relative;
    width: 100%;
}

.d-inline-block.text-icon .appkiticon {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.9rem;
    font-weight: 500;
}

.d-inline-block.text-icon input {
    padding-left: 25px;
}

/*textbox-with icons*/

.a-navigation {
    width: 260px;
    padding: 25px;
    position: fixed;
    top: 82px;
    bottom: 0;
    z-index: 10;
    overflow: auto;
    .a-accordion-wrapper {
        margin-top: 20px;
        padding-bottom: 200px;
        .a-accordion-title .a-accordion-title,
        .portfolio-title {
            background-color: #fff;
            height: auto;
            color: #7d7d7d;
            font-weight: 500;
            padding-left: 0;
            font-size: 0.9rem;
            padding-right: 10px;
            line-height: 1.5rem;
            cursor: pointer;
            margin-bottom: 10px;
            word-wrap: anywhere;
            word-break: break-word;
            &.active {
                color: #2d2d2d;
                font-weight: 600;
            }
            .appkiticon {
                float: none;
                display: inline-block;
                margin-right: 10px;
                font-size: 0.5rem;
                color: #7d7d7d;
                vertical-align: middle;
                margin-bottom: 2px;
            }
            .p-title {
                max-width: 175px;
            }
        }
        .a-accordion-content {
            border-left: 1px solid #ccc;
            padding-left: 20px;
            background-color: #fff;
            margin-bottom: 10px;
        }
        .project-list {
            border-left: 1px solid #ccc;
            padding-left: 20px;
            background-color: #fff;
            margin-bottom: 10px;
        }
        .project-list.collapse:not(.show) {
            display: block;
            overflow: hidden;
            height: 0;
            transform: scaleY(0);
        }
        .project-list.show {
            display: block;
            overflow: hidden;
            transition: transform 0.2s ease-out;
            height: auto;
            transform: scaleY(1);
            transform-origin: top;
        }
        .a-list-item {
            color: #7d7d7d;
            font-weight: 400;
            padding: 0;
            line-height: 1.5rem;
            .active {
                color: #eb590b;
            }
        }
    }
}

/*footer*/

.a-footer {
    padding: 20px 0px;
    position: fixed;
    background: #fff;
    bottom: 0;
    width: 243px;
    left: 0;

    padding-left: 20px;
    .add-project-button {
        font-size: 0.9rem;
        font-size: 0.9rem;
        display: inline-block;
        padding: 10px;
        margin-bottom: 0 !important;
        margin-left: -5px;
        &:hover {
            background: #e0e0e0;
        }
        .appkiticon {
            color: #eb590b;
            font-size: 38px;
            vertical-align: middle;
        }
    }
    .copyright-text a {
        color: #9e9e9e;
    }
}
.q-m-button {
    font-size: 14px !important;
    padding: 15px 10px !important;
    margin-bottom: 10px;
}
.q-m-button i {
    font-size: 8px !important;
}
.settings-a-wrapper {
    max-height: calc(100vh - 450px);
    padding-bottom: 20px !important;
    overflow: auto;
    margin-bottom: 10px;
}
/*footer*/
.q-m-button {
    height: 1.5rem;
}
.a-loading-one {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    background-image: url(https://sense-dev.pwc.com.au/assets/25a6699af2c37e2b97c8b1d8e8d89b45.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-animation: load3 0.6s infinite linear;
    animation: load3 0.6s infinite linear;
}

.a-loading-one.a-primary {
    background-image: url(https://sense-dev.pwc.com.au/assets/9d780b5571cf19844c3e41ddac3e519b.svg);
}
.a-text-input {
    border: none !important;
}
.a-text-input:disabled {
    color: #2d2d2d !important;
}
.content-container .a-btn.a-btn-lg {
    height: -0.875rem;
}

.port-sec {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .port-title {
        font-weight: 500;
    }

    .port-icon > i {
        color: #eb590b;
    }

}

.show-hide-section {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    .hide-all {
        font-size: 14px;
        cursor: pointer;
        font-weight: 500;
        color: #eb590b;
    }

    .show-all {
        color: #eb590b;
        margin-right: 30px;
        font-size: 14px;
        cursor: pointer;
        font-weight: 500;
    }

    .show-all.disabled {
        pointer-events: none;
        opacity: 0.6;
        color: #eb590b91;
    }

    .hide-all.disabled {
        pointer-events: none;
        opacity: 0.6;
        color: #eb590b91;
    }

}

.manage-portfolio-table {
   
    .show-in-dashboard {
        text-align: right;
    }

    .port-checkbox {
        text-align: right;

        .a-checkbox {
            margin: 0px !important;
        }
    }

    thead tr th, tbody tr td {
        padding: 0.75rem;
    }

    tbody tr {
        &:hover {
            background-color: #e8e8e8 !important;
        }
    }
    
}

#managePortfolio {
    table tbody {
        display: block;
        max-height: 300px;
        overflow-y: scroll;
      }
      
      table thead, table tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
}

.manage-portfolio-table .a-checkbox input[type=checkbox]:checked + .a-checkbox-mark .appkiticon {
    display: flex !important;
}

.port_active_name{
    color:#000000 !important;
    cursor: not-allowed;
}

.port_active_text{
    color: #ffffff !important;
    cursor: not-allowed;
}
.port_active{
    opacity: 0.5;
}
.port-tooltip{
    width: 9% !important;
    padding: 5px !important;
    text-align: center !important;
    margin-top: -4px !important;
}
