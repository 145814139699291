@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

.no-min-width {
  min-width: auto !important;
}
.a-accordion-wrapper.portfolio-accordion-wrapper {
  span.appkiticon-cust:before {
    color: grey;
  }
}
.add-project-sidebar {
  .SumoSelect.a-select > .CaptionCont > span {
    font-size: 13px;
  }

  .text-disabled {
    input:disabled {
      background-color: #f5f5f5;
    }

    .appkiticon {
      color: #989898;
    }
  }
}

.add-stakeholder-sidebar {
  .a-list {
    .a-list-item {
      .a-badge.a-badge-warning {
        color: #ffffff;
        background-color: #ffbf1f;
      }

      .appkiticon {
        cursor: pointer;
        margin-left: 10px;

        &:hover {
          color: #eb590b;
        }
      }
    }
  }
}

.a-main-content {
  min-height: calc(100vh - 220px);
}

a.disabled {
  pointer-events: none;
  cursor: default;
}
.widget-project-grid {
  display: grid;
  grid-auto-flow: column;
}
.data-container {
  .data-item-box {
    // cursor: pointer;
    width: 280px;
    margin-bottom: 20px;
    position: relative;
    .pop-out {
      position: absolute;
      top: 7px;
      right: 7px;
      z-index: 1;
      i {
        font-size: 18px;
        color: #bababa;
        cursor: pointer;
        &:hover {
          color: #eb590b;
        }
      }
    }
    .a-panel {
      position: relative;
      padding: 25px 20px;
      .a-accordion-wrapper {
        padding: 0;
        margin-bottom: 20px;
        .a-accordion-content {
          padding: 0;
          .project-section-title {
            vertical-align: middle;
            font-size: 0.875rem;
            font-weight: 500;
            color: #989898;
            text-align: left;
            margin-bottom: 10px;
            i {
              font-size: 22px;
              color: #747475;
              vertical-align: middle;
            }
          }
          .tag-container {
            .a-tag {
              width: 100%;
              justify-content: flex-start;
              display: flex;
              margin-bottom: 13px;
              white-space: normal;
              text-align: left;
              transition: 0.3s linear;

              &:hover {
                box-shadow: 0px 2px 5px 0px #00000029;
              }
            }
            .tag-blue {
              background-color: #e5f0ed;

              .right-icon {
                color: #66a295;
              }
            }

            .tag-warning {
              background-color: #ffe7ad;

              .right-icon {
                color: #deb450;
              }
            }

            .tag-danger {
              background-color: #f8d3cf;

              .right-icon {
                color: #e23d2c;
              }
            }

            .left-icon {
              margin-right: 10px;
            }

            .tag-title {
              font-size: 83%;
              color: #444444;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .right-icon {
              font-size: 0.8rem;
              margin-left: auto;
              justify-self: flex-end;
              font-weight: 600;
            }
          }

          .people-comments-section {
            text-align: left;

            .a-badge {
              text-transform: none;
              border-radius: 2px;
              padding: 5px 12px;
              margin-right: 5px;
              margin-bottom: 10px;
              cursor: pointer;
              font-weight: 400;
              height: auto;
              display: inline-block;
              max-width: 100%;
              line-height: 1;

              &.badge-danger {
                background: #c52a1b;
              }

              &.badge-warning {
                background: #ffbf1f;
              }

              &.badge-success {
                background: #23982e;
              }

              &.badge-general {
                background: #7d7d7c;
              }
            }
          }

          .survey-section {
            .a-customized-ratings {
              .a-ratings-detail {
                margin-bottom: 5px;

                span {
                  font-weight: normal;
                  font-size: 12px;
                }

                .a-ratings-total {
                  width: 100%;
                  height: 8px;
                  position: relative;

                  .average-marker {
                    position: absolute;
                    height: 25px;
                    width: 1px;
                    background: #e0301e;
                    top: -8px;

                    &[data-value="10"] {
                      left: 10%;
                    }

                    &[data-value="20"] {
                      left: 20%;
                    }

                    &[data-value="30"] {
                      left: 30%;
                    }

                    &[data-value="40"] {
                      left: 40%;
                    }

                    &[data-value="50"] {
                      left: 50%;
                    }

                    &[data-value="60"] {
                      left: 60%;
                    }

                    &[data-value="70"] {
                      left: 70%;
                    }

                    &[data-value="80"] {
                      left: 80%;
                    }

                    &[data-value="90"] {
                      left: 90%;
                    }

                    &[data-value="100"] {
                      left: 100%;
                    }
                  }

                  .a-ratings-percent {
                    height: 100%;
                    background-color: #634a8f;
                  }
                }
              }

              &.a-customized-white-background {
                background-color: #eee;
              }
            }

            .a-ratings-value {
              font-size: 0.75rem;
              font-weight: normal;
              color: #a6a6a6;
            }
          }
        }
      }

      .panel-expander {
        position: absolute;
        bottom: 10px;
        padding-left: 0;
        left: 50%;
        transform: translateX(-50%);
        padding-right:0;
        i {
          font-size: 22px;
          color: #a6a6a6;

          &:hover {
            color: #eb590b;
          }

          &.icon-up-chevron-fill:before {
            content: "";
          }
        }
      }

      .project-title {
        margin-bottom: 20px;

        p {
          color: #a6a6a6;
          overflow-wrap: anywhere;
          word-break: break-word;
        }
      }

      .project-percentage {
        margin-bottom: 10px;

        h3 {
          color: #6d6d6d;
          font-size: 32px;

          &.no-oc-data {
            position: relative;
            z-index: 0;

            &:before {
              position: absolute;
              z-index: 1;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              background: #c7c7c7;
              content: "";
              filter: blur(3px);
              -webkit-filter: blur(3px);
              height: 37px;
              width: 90px;
              display: block;
              margin: 0 auto;
            }
          }
        }
      }

      .project-progress {
        margin-bottom: 10px;

        p {
          font-size: 14px;
          color: #a6a6a6;

          i {
            color: #66bb6a;
            position: relative;
            min-width: 32px;
          }

          .grey-color {
            color: #a6a6a6 !important;
          }

          &.no-trends-data {
            .no-min-width {
              min-width: 32px !important;
            }
            i {
              &:before {
                position: absolute;
                z-index: 1;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: #c7c7c7;
                content: "";
                filter: blur(1px);
                -webkit-filter: blur(1px);
                width: 27px;
                display: block;
                margin: 0 auto;
              }
            }
          }
        }
      }

      .graph-line {
        margin-bottom: 15px;
        margin-top: 5px;

        p {
          color: #a6a6a6;
        }
      }
    }
    .a-panel {
      &:hover {
        .panel-expander {
          i {
            color: #eb590b;
          }
        }
      }
    }
  }
}

.r-s-subtitle {
  .a-form-label {
    font-size: 14px;
    color: #828282;
  }
}

.messaging {
  .incoming_msg_img {
    display: inline-block;
    width: 10%;

    img {
      width: 100%;
    }
  }

  .received_msg {
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: top;
    width: 88%;
    margin-bottom: 20px;

    p {
      background: #e0e0e0 none repeat scroll 0 0;
      border-radius: 4px;
      color: #2f2f2f;
      font-size: 13px;
      margin: 0;
      padding: 5px 10px 5px 12px;
      width: 100%;
    }

    .time_date {
      color: #747474;
      display: block;
      font-size: 12px;
      margin: 3px 0 0;
      text-align: right;
    }
  }
}

.capitalize-text {
  text-transform: capitalize;
  cursor: pointer;
}

.data-scroller-container {
  overflow: auto;
  min-height: 400px;
  height: calc(100vh - 220px);
  margin-top: 25px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #7f7f7f;
  }
}

.col-t1 {
  float: left;
  width: 280px;
  margin-right: 20px;
  padding: 0;
}

.custom-animation {
  animation-name: example;
  animation-duration: 0.5s;
  transform-origin: top;
}

@keyframes example {
  from {
    transform: scaleY(0);
    transform-origin: top;
  }

  to {
    transform: scaleY(1);
    transform-origin: top;
  }
}

@media screen and (min-width: 320px) and (max-width: 1200px) {
  .data-scroller-container {
    height: calc(100vh - 280px);
  }
}
.portfolio-stakeholder {
  .stakeholder-name {
    max-width: 160px;
    line-height: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.cstm-align {
  position: relative;
  top: 3px;
}
.some-insufficient-result-container {
  padding: 8px 0px 8px 20px;
  border-top: 1px solid #7d7d7d;
  border-bottom: 1px solid #7d7d7d;
  margin-top: 10px;
  h2 {
    font-size: 1rem;
    font-weight: 700;
    color: #7d7d7d;
    .insuficient-img-sml {
      background-image: url("../../../../assets/images/component/insufficient-results.svg");
      width: 2rem;
      height: 1.785rem;
      background-size: contain;
      background-repeat: no-repeat;
      float: left;
      margin-left: 25px;
      margin-right: 5px;
      // margin-top: 2px;
    }
  }
  p {
    font-size: 0.75rem; 
    color: #7d7d7d;
    margin: 0px;
  }
}
.panel-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
}

.panel-actions div:last-child a {
  color: #fa5927;
  text-transform: uppercase;
}
.custom-domain-icon {
  margin-right: 5px;
}

.help-icon-align{
  margin-bottom: 7px;
}
