.mySwiper {
    img {
        width: 323px ;
        margin: 0 auto;
        margin-bottom: 45px;
    }

    .swiper-pagination-bullet {
        background: transparent;
        border: 1px solid #7D7D7D;
        opacity: 1;
        width: 10px;
        height: 10px;
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: #7D7D7D;
    }

    .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: var(--swiper-pagination-bottom, 410px);
        top: var(--swiper-pagination-top, auto);
        left: 0;
        width: 100%;
    }

    .circular-bar {
        width: 200px;
        height: 200px;
        margin: 0 auto;
        margin-top: 30px;
    }
}

.bg-orange-img {
    background-image: url(/assets/5906c29afc96ccd997da6dbc54dcf031.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 40px 20px 40px;
    text-align: center;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;

    .bg-title {
        font-weight: 500;
        font-size: 20px;
        display: block;
    }

    .sp-label {
        background: #65ABEB;
        border-radius: 2px;
        font-weight: 500;
        font-size: 14px;
        color: #fff;
        padding: 10px;
        display: inline-block;
        position: relative;
        bottom: -20px;
    }
}

.main-content {
    min-height: calc(100vh - 242px);
    height: 100%;
    overflow-y: auto;
    margin-top: 120px;
    display: flex;
    align-items: center;
    justify-content: center;

    .content-wrap {
        width: 100%;
    }

    .content {
        text-align: center;

        h2.name {
            font-size: 20px;
            font-weight: 500;
            color: #7d7d7d;
            margin-bottom: 30px;
        }

        span.date {
            font-size: 14px;
            color: #bdbdbd;
        }

        p.desc {
            color: #BDBDBD;
            font-size: 14px;
            padding-top: 20px;
            max-width: 340px;
            margin: 0 auto;
        }

        .options {
            margin-top: 20px;
            margin-bottom: 20px;

            .butn {
                background: #828282;
                border-radius: 20px;
                padding: 10px;
                color: #fff;
                font-size: 14px;
            }

            .butn-theme {
                background: #CF4B01;
            }
        }
    }

}

.footer-btm {
    background: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
}

.fade-in-getting-started {
    -webkit-animation-name: fade-in-button;
    -webkit-animation-duration: 2s;
    animation-name: fade-in-button;
    animation-duration: 2s;
}

@media screen and (max-width: 414px) {
    .wrapper.get-start-content {
        height: calc(100vh - 136px);
        overflow-y: auto;

        .description {
            width: 80%;
        }
    }
}

.wrapper {
    height: calc(100vh - 45px);
    display: flex;
    align-items: center;
}

.public-container {
    position: relative;
    background-color: #E9E7E9;
    overflow-y: auto;

    .privacy-content {
        .container {
            background-color: rgba(0, 0, 0, 0);
            max-width: 40rem;
            margin: 4.9rem auto 0;

            .privacy-content-container {
                padding: 25px;
                width: 100%;
                margin: 0 auto;
                max-width: 850px;

                .privacy-list {
                    padding-left: 3rem;

                    li {
                        list-style: disc;
                    }
                }

                .padding-formatting {
                    padding-left: 30px;
                }

                .app-buttons {
                    margin-bottom: 40px;

                    img {
                        width: 165px;
                    }
                }

                p {
                    margin-top: 0;
                    margin-bottom: 15px;
                    font-size: 0.95rem;
                    color: #000;
                }

                a {
                    &.hyperlink {
                        color: #0000EE
                    }

                    text-decoration: underline;
                }

                h5 {
                    color: #4a4a4a;
                }

                h6 {
                    color: #000000;
                }

                i {
                    font-style: italic;
                }

                .terms-of-use-item {
                    a {
                        color: #0000EE;
                    }

                    .term-pd-1 {
                        padding-left: 1.5rem;
                    }

                    .term-pd-2 {
                        padding-left: 2.8rem;
                    }

                    .term-pd-3 {
                        padding-left: 4.8rem;
                    }
                }
            }
        }
    }

    .lower-right-image {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .get-started-img img {
        object-fit: cover;
        width: 500px;
    }

    .description {
        width: 60%;
    }
}

@media (min-width: 1200px) {
    .get-started-img img {
        object-fit: cover;
        width: 55%;
        height: auto;
    }
}

.page-content {
    .press-enter {
        display: block;
    }
}

.content-title {
    font-size: 20px;
    font-weight: bold;
    color: #6e6e6e;
    display: block;
    margin-top: 10px;
}

.description-container {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 40px;
}

@media screen and (max-width: 414px) {
    .wrapper.get-start-content {
        height: calc(100vh - 136px);
        overflow-y: auto;

        .description {
            width: 80%;
        }
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .description {
        width: 80%;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .get-started-img img {
        object-fit: cover;
        width: 500px;
    }

    .description {
        width: 60%;
    }
}

.description {
    margin: 0 auto;
    font-size: 16px;
    color: #6e6e6e;
    display: block;
    line-height: 1.44;
}

.get-started-btn {
    font-size: 16px;
    font-weight: bold;
    border-radius: 2px;
    background-color: #e05d0c;
    color: #fff ;
    width: auto ;
    box-shadow: none ;
    height: auto ;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    padding: 10px 31px !important;
}
.button-create-topic {
    padding: 10px 27px !important;
  }

.footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 30px;
}

@media (min-width: 768px) and (max-width: 991.98px) {


    .footer-wrapper {
        padding: 17px 30px;
        background: #fff;
    }

    .footer-img img {
        width: auto;
    }

    .footer-link {
        font-size: 16px;
    }

    footer.footer {
        position: fixed;
        width: 100%;
        bottom: 0;
        background: #fff;
    }

}

@media (min-width: 992px) and (max-width: 1199.98px) {
    footer.footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #fff;
    }

    .footer-wrapper .footer-img img {
        width: auto;
    }

    .footer-wrapper {
        padding: 10px 30px;
    }

}

@media screen and (max-width: 320px) {
    .footer-img {
        padding: 0px 0 15px 0;
    }
}

@media screen and (min-width: 321px) and (max-width: 767px) {
    .footer-img {
        padding: 0px 0 15px 0;
    }
}

.logo-text {
    opacity: 0.4;
}

.loader-logo .logo-text {
    font-size: 19px;
    font-weight: bold;
}

.footer-links-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-link {
    color: #6d6d6d;
    font-size: 14px;
    padding-right: 10px;
    padding-left: 10px;
    text-decoration: underline;
    border-right: 1px solid #dddddd;
}

.footer-link:last-child {
    padding-right: 0px;
    border: none;
}