/*reuse css*/

body {
  overflow-x: hidden !important;
  // background-image: url("../../../assets/images/component/lower_right.png");
  background-size: 511px;
  background-position: right bottom;
  background-color: #e8e8e8;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.user-filters {
  background-color: #d8d8d8;
  padding: 5px 10px 5px 5px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 15px;
  label {
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 0.1rem;
  }
  [class*=col-] {
    padding-right: 0px;
  }
}

.w-43 {
  width: 43px;
}

.modal-open {
  .a-main-content {
    z-index: inherit;
  }
}

.w-80 {
  width: 80px !important;
}

a,
a:hover,
a:focus {
  color: #333;
}

.hover-effect {
  .a-list-item {
    & > .appkiticon {
      cursor: pointer;

      &:hover {
        color: #eb590b !important;
      }
    }
  }
}

.form-group {
  div.error {
    float: right;
    color: red;
    font-size: 0.75rem;
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

div.error {
  float: right;
  color: red;
  font-size: 0.75rem;
  margin-top: 4px;
  margin-bottom: 4px;
}

div.error.block {
  float: unset;
}
#attr-error-block {
  float: unset;
  color: red;
  font-size: 0.75rem;
  margin-top: 4px;
  margin-bottom: 4px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-move {
  cursor: move !important;
}

/*reuse css*/

.a-main-content {
  position: relative;
  z-index: 9;
}

.active-icon {
  background: #fff !important;
}

.content-container {
  margin-top: 82px;
  margin-left: 260px;
  padding: 25px;
  min-height: calc(100vh - 82px);

  .lower-right-image {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 0;
  }

  .a-page-header {
    .a-btn.a-btn-secondary {
      border: 0.0625rem solid #eb590b;
      color: #eb590b;
    }
    .a-page-subtitle {
      font-size: 14px;
      text-transform: uppercase;
      color: #7d7d7d;
    }

    .page-title-section {
      max-width: 50%;

      .text-ellipses {
        // width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
      .port-text-ellipses {
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      }
    }

    .a-page-title {
      font-size: 23px;
      color: #eb590b;
      font-weight: 400;
      word-break: break-word;
      overflow-wrap: anywhere;
      cursor: text;
    }

    .project-state {
      margin-left: 2%;
      vertical-align: top;
      max-width: 32%;
      margin-top: 6px;

      .a-dropdown-toggle {
        background: #7d7d7c;
    border-radius: 2px;
    color: #fff;
    padding: 3px 6px;
    text-transform: uppercase;
    font-size: 12px;
    min-width: 65px;
    justify-content: center;
    height: 24px;
    align-items: center;
        .a-choosen-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .a-icon {
          display: none;
        }
      }

      .a-dropdown {
        .a-dropdown-menu {
          min-width: 180px;

          .a-dropdown-item {
            &:hover {
              background-color: #faede5;
            }
          }
        }

        &.a-show {
          .a-dropdown-toggle {
            box-shadow: none;
          }
        }
      }
    }

    .settings-title {
      .a-page-title {
        font-size: 23px;
        color: #464646;
        font-weight: 400;
      }
    }
  }

  .right-sidebar.r-s-active {
    right: 0;
  }

  .r-s-closed {
    right: -100%;
  }

  .right-sidebar {
    background-color: #fff;
    position: fixed;
    top: 82px;
    right: 0;
    bottom: 0;
    width: 420px;
    padding: 20px;
    box-shadow: -4px 2px 7px #a7a7a7;
    z-index: 100;
    overflow: AUTO;
    .a-btn.a-btn-primary {
      background-color: #eb590b;
      color: #fff;
    }
    .form-group {
      margin-bottom: 25px;

      .a-toggle {
        font-weight: normal;

        .a-toggle-mark {
          min-width: 55px;
          .on {
            color: #fff;
            justify-content: flex-start;
            padding-left: 9px;
          }
          .off {
            justify-content: flex-end;
            padding-right: 9px;
            color: #fff;
          }
        }

        .a-toggle-text {
          font-size: 14px;
        }
      }
    }

    .a-checkbox {
      .a-checkbox-text {
        font-weight: 500;
        color: #424242;
        overflow-wrap: anywhere;
      }
    }

    .icon-item-box {
      background: #fff;
      height: auto;
      cursor: pointer;
      padding-left: 0;
      margin-bottom: 20px;
      padding: 0.125rem 0.625rem;

      i {
        margin: 0;
      }
    }

    .r-s-title {
      color: #464646;
      font-size: 18px;
      font-weight: bold;

      .appkiticon {
        float: right;
        margin-right: 10px;
        color: #2d2d2d;
        font-size: 16px;
        margin-top: 7px;
        cursor: pointer;
      }
    }

    .r-s-body {
      .a-form-label {
        font-size: 13px;
        text-transform: uppercase;
      }

      .section-title {
        font-size: 17px;
        color: #464646;
      }

      .SumoSelect.a-select > .CaptionCont {
        border: none;
        font-size: 14px;
      }

      .portfolio-admins {
        .a-list {
          .a-list-item {
            padding-left: 0;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0px;
            }

            & > div.a-font-md {
              color: #424242;
            }
          }
        }

        .add-admin-button {
          font-size: 14px;
          margin-top: 10px !important;
          margin-bottom: 10px;

          .appkiticon {
            font-size: 19px;
            color: #eb590b;
            vertical-align: middle;
          }
        }
      }
    }

    .r-s-actions {
      margin-bottom: 20px;

      .action-text {
        font-size: 0.9rem;
        margin-top: 15px;
        display: inline-block;
        color: #464646;
      }
    }
  }
}

.optional-comment-ref {
  .right-sidebar {
    padding: 0px;
  }
}

.a-list.cross-list {
  .a-list-item {
    padding-left: 0;
    margin-bottom: 10px;

    .appkiticon {
      cursor: pointer;
      margin-left: 10px;

      &:hover {
        color: #eb590b;
      }
    }
  }
}

.right-sidebar::-webkit-scrollbar-track,
.a-navigation::-webkit-scrollbar-track,
.lifestyle-stages::-webkit-scrollbar-track,
.domain-list::-webkit-scrollbar-track,
.a-table-scroller::-webkit-scrollbar-track,
.settings-a-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.right-sidebar::-webkit-scrollbar,
.a-navigation::-webkit-scrollbar,
.lifestyle-stages::-webkit-scrollbar,
.domain-list::-webkit-scrollbar,
.a-table-scroller::-webkit-scrollbar,
.settings-a-wrapper::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
  height: 4px;
}

.right-sidebar::-webkit-scrollbar-thumb,
.a-navigation::-webkit-scrollbar-thumb,
.lifestyle-stages::-webkit-scrollbar-thumb,
.domain-list::-webkit-scrollbar-thumb,
.a-table-scroller::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
}

// .a-accordion-content.show {
//   display: inherit !important;
// }

.footer-dropdowns {
  .a-btn {
    text-transform: uppercase;

    .appkiticon {
      margin-top: 4px;
    }
  }
}

//settings table
.setting-table {
  &.a-table {
    td {
      font-weight: 400;
      padding: 0.5rem 0.625rem;
      padding-left: 10px;
      margin-left: 30px;
      font-size: 0.875rem;
      line-height: 20px;
      vertical-align: top;
    }

    th {
      padding: 0.5rem 0.625rem;
      line-height: 20px;
      vertical-align: top;
    }
  }
}

td.t-cross-icon {
  text-align: right !important;
}

.a-navigation {
  .a-accordion-wrapper {
    .portfolio-title:not(.collapsed) {
      .appkiticon {
        &:before {
          transform: rotate(180deg);
          transform-origin: center;
        }
      }
    }
  }
}

.a-page-header {
  position: relative;
  z-index: 8;
}

//sumoselect design fix
.SumoSelect {
  cursor: pointer;

  .select-all {
    &.selected {
      & > span {
        i {
          background-color: rgb(250, 89, 39);
        }
      }
    }
  }

  .select-all {
    &.partial {
      > span {
        i {
          background-color: rgb(250, 89, 39);
        }
      }
    }
  }

  &.open {
    .CaptionCont {
      & > label {
        & > i::before {
          transform: rotate(180deg);
          transform-origin: center;
        }
      }
    }

    & > .optWrapper {
      top: 34px !important;
      width: 100%;
    }
  }

  & > .optWrapper {
    & > .options {
      li {
        &.opt {
          height: auto;

          &.selected {
            label {
              font-weight: 500;
            }
          }

          label {
            margin-top: 0;
            word-break: break-word;
            white-space: normal;
            overflow-wrap: anywhere;
          }
        }

        ul {
          li {
            &.opt {
              padding: 0.625rem;
            }
          }
        }

        &.group {
          & > label {
            text-transform: uppercase !important;
            font-size: 0.85rem !important;
            color: #737373 !important;
          }
        }
      }
    }
  }

  .optWrapper {
    &.multiple {
      & > .options {
        li {
          &.opt {
            &.selected {
              span {
                i {
                  background-color: rgb(250, 89, 39);
                }
              }
            }
          }
        }
      }
    }
  }

  & > .CaptionCont {
    position: relative;
    min-height: 0.875rem;
    background-color: #fff;
    border-radius: 0.125rem;
    margin: 0;
    height: 2.125rem;
    line-height: 2.125rem;
    padding: 0 0.625rem;
    display: flex;
    align-items: center;
    border: none;

    & > span {
      cursor: pointer;
      font-size: 14px;

      &.placeholder {
        color: #9e9e9e;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      }
    }

    & > label {
      & > i {
        position: absolute;
        right: 0.625rem;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        color: #9e9e9e;
        font-size: 0.75rem;
        height: auto;
        bottom: auto;
        background-image: none;

        &::before {
          content: "\e84c";
          font-family: appkit-font;
          font-style: normal;
          font-weight: 400;
          display: inline-block;
          text-decoration: inherit;
          width: 1em;
          text-align: center;
          font-variant: normal;
          text-transform: none;
          line-height: 1em;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  &:focus {
    & > .CaptionCont {
      box-shadow: none;
      border-color: rgba(0, 0, 0, 0);
    }
  }

  &:hover {
    & > .CaptionCont {
      box-shadow: none;
      border-color: rgba(0, 0, 0, 0);
      cursor: pointer;
    }
  }

  &.open {
    & > .CaptionCont {
      box-shadow: 0 0 0 0.125rem #eb590b;
      border-color: rgba(0, 0, 0, 0);
    }
  }
}

.a-list {
  .a-list-item {
    word-break: break-word;
  }
}

@media screen and (min-width: 706px) and (max-width: 767px) {
  .mr-cust1-2 {
    margin-right: 20px;
  }
}

@media screen and (min-width: 992px) {
  .row.general-row {
    .col-lg-3 {
      min-width: 400px;
    }
  }

  .domain-row {
    .col-lg-6 {
      min-width: 600px;
    }
  }

  .domain-heading-row,
  .attribute-header-row {
    .col-lg-3 {
      min-width: 400px;
    }
  }
}

@media screen and (max-width: 992px) {
  .row.general-row {
    .col-lg-3 {
      min-width: 350px;
    }
  }

  .domain-row {
    .col-lg-6 {
      min-width: 400px;
    }
  }

  .domain-heading-row,
  .attribute-header-row {
    .col-lg-3 {
      min-width: 400px;
    }
  }

  .content-container {
    .a-page-header {
      .page-title-section {
        max-width: 100%;
      }
    }
  }

  .content-container {
    .a-page-header {
      .project-state {
        width: auto;
        margin-left: 1%;
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .domain-row {
    .col-lg-6 {
      min-width: 100%;
    }
  }

  .domain-heading-row,
  .attribute-header-row {
    .col-lg-3 {
      min-width: 100%;
    }
  }
}

.a-notification {
  &.negative {
    background-color: #c62b12;
    color: #ffffff;
    position: fixed;
  }
}
.attributes-container {
  .filter-label {
    margin-bottom: 10px;
    margin-top: 0;
  }
}

.domain-container,
.users-container,
.attributes-container {
  height: calc(100vh - 210px);
  min-height: 280px;
}

.no-content-found {
  position: absolute;
  top: 40%;
  transform: translate(-50%);
  left: 50%;
  font-size: 20px;
  font-weight: 600;
  color: #ababab;
  width: 360px;
  text-align: center;
}

//loader css
.loader-container {
  & > div:not(.a-form-label) {
    display: inline-block;
    width: 89%;
    vertical-align: middle;
  }
}

.loader-container {
  & > button {
    display: inline-block;
    width: 11%;
    vertical-align: middle;
  }
}

.loader-button {
  min-width: 6.25rem;
  &:disabled {
    background-color: #eb590b !important;
    color: #ffffff !important;
    opacity: 0.7;
  }
  &.a-btn-transparent {
    background-color: #ffffff !important;
  }
}

.loader-button-skip {
  min-width: 6.25rem;
  &:disabled {
    color: #ffffff !important;
    opacity: 0.7;
  }
  &.a-btn-transparent {
    background-color: #ffffff !important;
  }
}

.attributes-container,
.users-container {
  .a-selector-wrapper {
    min-height: 64px;
  }
}

.theme-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

.a-modal {
  &.modal {
    &.add-stakeholder-modal {
      .modal-dialog {
        .modal-content {
          .a-modal-footer {
            button {
              text-transform: uppercase;
              &:hover {
                background-color: #eeeeee;
                color: #2d2d2d;
              }
            }
          }
        }
      }
    }
  }
}

.__react_component_tooltip {
  max-width: 400px;
  width: 100%;
}
.placeholder-text {
  color: #9e9e9e;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.well {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50% , -50%);
    text-align: center;
    margin-top: 150px;
    width: 100%;
    z-index: 91;
    h1 {
      font-weight: bold;
      font-size: 26px;
      opacity: 0.7;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 0;
      margin-top: 0;
      font-size: 0.8rem;
    }
}

.toggle-btn-wrap {

  .toggle-btn {
    background: #fff;
    border: 1px solid #cecece;
    font-size: 12px;
    padding: 4px 0px;
    color: #eb590b;
    outline: none !important;
  }
  .toggle-r {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.toggle-l {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.toggle-btn.active {
  color: #fff;
  background: #eb590b;
  border-color: #eb590b;
}

}


.limit-wrap.text-right {

  margin-top: 4px;
  small {
    color: #000;
    font-size: 11px;

  }
} 

.c-question-box {
  font-size: 13px;
  resize: none;
  box-shadow: none;

  &:focus {
    box-shadow: none;
    border-color: #eb590b;
  }
}

.btn-ask-qwestion {
  background: #eb590b !important;
  color: #fff;
  box-shadow: none !important;
  margin-left: 8px;
}

.modal-h-tagline {
  font-weight: 500;
}

.y-notice-bar {
  background: #f6c343;
  position: absolute;
  width: calc( 100% + 50px );
  padding: 5px;
  box-shadow: 0px 2px 5px #00000036;
  z-index: 10;
  top: -25px;
  left: -25px;
}
.btn-notice {
  width: 90px;
  padding: 3px;
  box-shadow:none !important;
}

.y-notice-text {
  font-size: 14px;
  font-weight: 600;
}
.btn-notice-preview {
  background: #fff;
  color: #dc6830;
  border: 1px solid #dc6830;
}
.btn-notice-preview:hover {
  background: #fff;
  color: #dc6830;
  border: 1px solid #dc6830;
}

.btn-notice-send {
  
  color: #fff !important;
  border: 1px solid #dc6830;
  background: #dc6830;
}

.btn.c-question-cancel {
  font-size: 0.825rem;
  font-weight: 500;
  margin-right: 5px;
  box-shadow: none !important;
}

.a-modal-footer.c-question-ask .a-btn-transparent {
  background: #dc6830 !important;
  color: #fff !important;
  font-size: 0.825rem !important;
}

.in-active-badge {
  background: #eaeaea;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  padding: 3px 6px;
  border-radius: 2px;
  color: #7d7d7d;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 18px;
}
.in-active-badge.iab-dark {
  background: #dbdbdb;
}
.in-badge {
  .in-active-badge {
    margin-left: 8px;
  }
  .p-title {
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.p-title {
  display: inline-block;
  vertical-align: middle;
}

.right-bar-overlay:before {
  content: "";
  background: #000;
  position: fixed;
  top: 82px;
  left: 0;
  opacity: .5;
  right: 0;
  bottom: 0;
  z-index: 99;
}

.a-btn.a-btn-secondary:disabled:not(.a-no-interaction) {
  border: 0.0625rem solid #eb590b !important;
  color: #eb590b !important;
  cursor: not-allowed !important;
  opacity: 0.6 !important;
}


body.sidebar-opend {
    overflow: hidden;
}

body.sidebar-opend.optional-comment {
  .content-container .right-sidebar {
    padding: 0px;
  }
}

body.sidebar-opend.hover-active-point {
  .content-container .right-sidebar {
    overflow: visible;
  }
}

body.sidebar-opend:after {
  content: "";
  position: fixed;
  background: #000;
  bottom: 0;
  left: 0;
  top: 82px;
  right: 0;
  opacity: .3;
  height: 100%;
  z-index: 99;
  transition: .3s all ease;
}

.ac-resend-button.a-btn.a-btn-secondary.a-btn-sm.resend-btn.bg-white.pl-0.border-0:disabled {
    border: none !important;
    color: #7d7d7d !important;
    cursor: not-allowed !important;
}

#userCsvModal .a-modal-footer
{
  position: relative;
}