.circle-badge {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    margin-left: 2px;
}

.circle-badge.green {
    background: green
}

.circle-badge.yellow {
    background: yellow
}

.circle-badge.red {
    background: red
}

.dd-header span, .dd-footer small {
    font-size: 12px;
    color: #cccccc;
    line-height: 12px;
    display: block;
    margin: 5px 0;
}

.dd-header span {
    color: #aaa;
}

.dd-footer small {
    display: flex;
}

.dd-footer small #completionMessage {
    margin-right: 5px;
    font-size: 16px;
}

.badge-dropdown .dropdown-menu {
    width: 100%;
    max-width: 350px;
    min-width: 350px;
}

.badge-dropdown .dropdown-menu .dropdown-item {
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
}

.dd-body>div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dd-body {
    max-height: 253px;
    overflow: auto;
}

.badge-dropdown .dropdown-menu .dropdown-item span {
    display: block;
    font-size: 11px;
    color: #aaa;
}

/* .badge-dropdown .dropdown-menu .dropdown-item:before {
    content: "";
    position: absolute;
    left: 4px;
    top: 50%;
    background: red;
    width: 14px;
    height: 14px;
    margin-top: -7px;
    border-radius: 50%;
} */

a.dropdown-item div {
    display: flex;
    align-items: center;
}

a.dropdown-item span {
    padding-left: 1.5rem;
    ;
}

.badge-dropdown:after, .dropdown-toggle::after {
    display: none;
}

.show {
    display: block !important;
}

.dropdown .dropdown-toggle {
    height: auto !important;
    border: none !important;
}

.dropdown .dropdown-menu {
    padding: 1rem!important;
}

.dropdown-toggle .__react_component_tooltip ul li {
    list-style-type: disc;
    position: relative;
    left: 15px;
    padding-top: 5px;
    word-break: normal;
    white-space: normal;
    padding-right: 1rem;
    text-align: justify;
}

.dropdown-toggle .__react_component_tooltip {
    max-width: 200px;
    padding: 8px 10px !important;
}

.dropdown-toggle .__react_component_tooltip.every-one {
    padding: 8px 0px !important;
    text-align: center;
    width: 125px;
}

.dropdown-toggle .__react_component_tooltip.every-one::before {
    display: none;
}

.dropdown-menu .data-scroller-container {
    margin-top: 0px !important;
    height: 100% !important;
    min-height: auto !important;
}

.slctd-sgmnts {
    font-size: 13px;
    font-weight: 700;
    padding-left: 3px;
    color: #eb590b;
}

.cstm-chk-drpdwn {
    display: flex;
    flex-direction: row;
}

.cstm-chk-drpdwn p {
    margin: 0px !important;
    color: #212529 !important;
    font-weight: 500;
}

.cstm-chk-drpdwn button:focus {
    outline: none !important;
}

.cstm-chk-drpdwn .pro-reset-button {
    position: absolute;
    right: 13px;
}

.chkbx-sgmnt i {
    position: absolute;
}

.chkbx-sgmnt span {
    font-size: 15px;
    color: #7d7d7c !important;
    font-weight: 500;
    /* overflow: hidden; */
    word-wrap: break-word !important;
    display: inline-block !important;
    white-space: pre-line;
    line-height: normal;

}

.dd-body label.a-checkbox {
    z-index: -1;
}

.dd-body a.dropdown-item:active {
    background-color: var(--theme-color-btnprihover);
}

.body-input-container label {
    width: auto !important;
    border: none !important;
}

.sumo-select .dropdown-menu {
    width: 100%;
    max-width: 500px;
    min-width: 500px;
}

.sumo-select .body-input-container {
    border-bottom: 1px solid #d8d8d8 !important;
    margin-bottom: 0 !important;
}

/* .sumo-select .body-input-container:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
} */

.sumo-select .dropdown .dropdown-menu {
    padding: 0 !important;
}

.sumo-select a.dropdown-item {
    padding: 0.25rem 0 !important;
}