.multicolor-bar {
	margin-bottom: 70px;
	padding: 0px 12px;
}

.multicolor-bar .values {
	display: flex;
	.value {
		text-align: center;
		min-width: 30px;
		max-width: 286px;
	}
}

.multicolor-bar .values .value {
	.data {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: center;
		letter-spacing: 0px;

		span {
			font-size: 0.875rem;
		}
	}
	.data.skip-next-color .material-icons {
		font-size: 1.2rem;
	}
	span {
		display: inline;
	}
}

.multicolor-bar .scale .graduation {
	float: left;
	text-align: center;
}

.multicolor-bar .bars .bar {
	float: left;
	height: 20px;
	min-width: 30px;
}

.multicolor-bar .bars {
	width: 100%;
	display: flex;
	.bar.thumb-down-color {
		background-color: #f17b6f;
	}

	.bar.thumb-up-color {
		background-color: #759e93;
	}

	.bar.skip-next-color {
		background-color: #7d7d82;
	}

	span {
		font-size: 0.875rem;
	}
}

.multicolor-bar .bars div.bar:first-of-type {
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.multicolor-bar .bars div.bar:last-of-type {
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.multicolor-bar .legends {
	text-align: center;
}

.multicolor-bar .legends .legend {
	display: inline-block;
	margin: 0 5px;
	text-align: center;
}

.multicolor-bar .legends .legend .dot {
	font-size: 25px;
	vertical-align: middle;
}

.multicolor-bar .legends .legend .label {
	margin-left: 2px;
	vertical-align: middle;
}
.multicolor-bar .bars {
	overflow: hidden;
	border-radius: 2px;
}

.bars.bar-3 .bar {
	max-width: 256px;
}
.values.bar-3 .value {
	max-width: 256px;
}
