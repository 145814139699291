.content-container {
  overflow: auto;

  .a-main-content {
    min-width: 850px;
  }

  .SumoSelect {
    max-width: 100%;
  }
}

.a-form-label {
  font-size: 13px;
  text-transform: uppercase;
}

.section-title {
  font-size: 17px;
  color: #464646;
}

.SumoSelect.a-select > .CaptionCont {
  border: none;
  padding-left: 0;
}

.add-text-button {
  .appkiticon {
    font-size: 20px;
    color: #eb590b;
    vertical-align: middle;
  }
}

.a-list.custom-check {
  .a-list-item {
    padding: 0 0.625rem;
    min-height: 35px;
    line-height: 35px;
    overflow: hidden;

    &:first-child {
      background-color: #e0e0e0;
    }

    .a-checkbox-text {
      font-weight: 400;
      border-left: none;
      padding-left: 10px;
      margin-left: 30px;
      min-height: 35px;
      display: table;
      padding-right: 60px;
      height: 35px;

      .domain-title-text {
        line-height: 25px;
        display: table-cell;
        vertical-align: middle;
        overflow-wrap: anywhere;
      }
    }

    .a-checkbox input[type="checkbox"]:checked + .a-checkbox-mark {
      background: #4a4747;
      border: none;
    }
  }
}

.footer-dropdowns {
  position: relative;
  display: inline-block;
  width: 100%;

  .dropdown-menu {
    max-width: 250px;
    padding: 10px 0;
    left: 0;
    top: -65px;

    & > li {
      cursor: pointer;
    }

    .user-infor-list {
      border: none;

      .user-infor-list-item {
        padding: 10px;

        a:focus {
          outline: none;
          text-decoration: none;
        }
      }

      .user-infor-list-item:hover {
        font-weight: normal;
        background: #eee;
      }
    }
  }
}

.filter-label {
  font-size: 13px;
  margin-top: 7px;
  display: inline-block;
}
.users-container {
  .a-table {
    .a-checkbox input[type="checkbox"]:checked + .a-checkbox-mark {
      background: #5e5e5e;
      border: none;
    }
  }

  .a-table-wrapper .a-dropdown-toggle {
    background-color: #fff;
  }

  .min-w-1024 {
    min-width: 1024px;
  }

  .min-w-2050 {
    min-width: 2050px;
  }

  .w-300 {
    width: 300px;
  }

  .w-43 {
    width: 43px;
  }
}

.user-sidebar-container {
  .form-group {
    .a-toggle {
      font-weight: normal;

      .a-toggle-text {
        font-size: 14px;
      }
    }
  }
}

.question-sidebar-container {
  .a-btn-group {
    .a-btn-group-item:not([disabled]).active {
      background: #eb590b;
      transition: color 0.25s ease;
      color: #ffffff;
      border-color: #eb590b;
    }
  }
}

.lifecycle-stages {
  .a-radio-text,
  .a-checkbox-text {
    font-weight: normal;
  }
}

.table-filter-icon {
  margin-right: 10px;
  min-width: 24px;
}

.filter-row {
  padding-left: 15px;
  margin-bottom: 15px;
}

/*textbox-with icons*/

.text-icon {
  position: relative;

  .appkiticon {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.9rem;
    font-weight: 500;
  }

  .material-icons {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.9rem;
    font-weight: 500;
  }

  input {
    padding-left: 25px;
  }

  &.icon-right {
    .material-icons {
      right: 2%;
      left: auto;
      color: #a2a2a3;
      font-size: 20px;
    }

    .appkiticon {
      right: 5px;
      left: auto;
    }

    input {
      padding-right: 25px;
      padding-left: 10px;
      width: 100%;
    }
  }
}

/*textbox-with icons*/

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .user-filters {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .filter-row {
    padding-left: 0;
  }
}

/* custom filter box */
// @media (min-width: 768px){
//     .seven-cols .col-md-1,
//     .seven-cols .col-sm-1,
//     .seven-cols .col-lg-1  {
//         width: 100%;
//         *width: 100%;
//     }
// }
@media (min-width: 768px) {
  .seven-cols .cstm-col-md-1 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
    flex: 1 0 12.33333%;
  }
}
.r-s-header > span {
  font-size: 10px;
}

span.required-star {
  color: red;
  font-size: 10px;
  position: relative;
  top: -4px;
}
.reset-button:not(.pro-reset-button) {
  display: flex;
  align-self: flex-end;
  margin-bottom: 10px;
}
.label-help {
  font-size: 10px;
  float: right;
  color: #ccc;
}

.reset-button a {
  color: #eb590b;
  font-size: 12px;
  margin-left: 10px;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 38px;
}
.user-filter-icon.table-filter-icon {
  margin-right: 10px;
  min-width: 24px;
  display: flex;
  align-self: flex-end;
  margin-bottom: 10px;
}
.cstm-tag-label {
  width: 90%;
}
.table-dropdown.show .table-dropdown-toggle i {
  color: #eb590b;
}
.table-dropdown.show .dropdown-menu {
  width: 100px;
  padding: 10px 0;
}

.table-dropdown.show .dropdown-menu ul {
  border-bottom: none !important;
}
.table-dropdown.show .dropdown-menu ul li:hover {
  font-weight: normal;
  background: #eee;
}
.pwc-progress-bar {
  width: 100%;
  max-width: 600px;
  background: #fff;
  height: 50px;
  position: absolute;
  top: 0;
  display: flex;
  box-shadow: 0 0 7px #ccc;
  border-radius: 3px;
}
@media screen and (min-width: 36rem) {
  .a-sm-fit {
    width: max-content;
  }
}

.a-alert.custom-alert {
  position: fixed;
  top: 13%;
  z-index: 9999;
  left: 50%;
  transform: translateX(-50%);
}
body.alert-open .a-main-content {
  z-index: 999;
}
.content-container .SumoSelect {
  height: 2.125rem;
}

.filter-row .SumoSelect {
  width: 100%;
}
textarea.a-text-input:not(:disabled):focus,
textarea.a-text-input:not(:disabled):active {
  box-shadow: 0 0 0 0.13rem var(--theme-color-control);
}
.user-filters .SumoSelect {
  width: 100% !important;
}

.text-theme-color:disabled:not(.a-no-interaction) {
  color: #ffceb4 !important;
  background: white !important;
}
// filter elements

.text-theme-color{
  color: var(--theme-color-control);
  font-size: 13px;
  background: transparent !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 700;
  &:hover, &:focus{
    color: var(--theme-color-control); 
    outline: none;
  }
}

a.text-theme-color{
  color: var(--theme-color-control);
  font-size: 13px;
  background: white !important;
  &:hover, &:focus{
    color: var(--theme-color-control); 
  }
}

.custom-select-filter{
  p.CaptionCont {
    background-color: transparent;
    border: none;
    span.placeholder{
      padding-right: 10px;
      font-size: 13px;
      color: var(--theme-color-samplecodeicon);
    }
    label{
      position: relative !important;
      i{
        font-size: 12px;
        color: var(--theme-color-samplecodeicon);
      }
    }
  }
  &:hover, &:focus{
    p.CaptionCont{
      background-color: transparent;
      border: none;
    }
  }
}
.SettingSumoSelect .SumoSelect:not(.disabled):focus > .CaptionCont, .SettingSumoSelect .SumoSelect:not(.disabled).open > .CaptionCont{
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.SettingSumoSelect {
  .SumoSelect{
  max-width: max-content;
p.CaptionCont {
    background-color: transparent;
    border: none;
    span.placeholder{
      padding-right: 10px;
      font-size: 13px;
      color: var(--theme-color-samplecodeicon);
      background: unset;
    }
    label{
      position: relative !important;
      width: 16px !important;
      i{
        font-size: 12px;
        color: var(--theme-color-samplecodeicon);
      }
    }
  }
  &.open{
    .optWrapper {
    width: 180px !important;
}
  }
}
}


  // custom input text with icon

  .input-group-with-icon{
    position: relative;
    .custom-input-filter-icon{
    padding-left: 2.2rem;
    position: relative;
    font-size: 13px;
    line-height: 34px;
  }
   .custom-input-filter-icon:focus +i{
color: var(--theme-color-control);
  }
  i{
    position: absolute;
    left: 0;
    top: 50%;
    line-height: 34px;
    padding: 0;
    color: var(--theme-color-samplecodeicon);
    transition: 0.3s;
    transform: translate(5px, -50%);
  }
  }
  
  //clear button sumo select
  .SettingSumoSelect{
  .SumoSelect {
    &:hover{
          background-color: #f3f3f3;
    }
    > .optWrapper.multiple {
      padding: 0;
      
      > .options {
        li.clear-button {
          padding-left: 0.625rem;
          &.selected, &:hover{
            background-color: transparent;
          }
          span{
          display: none;
    }
  label{
    display: block; text-align: right;
    color: #c7c7c7;
    
  }
&.active{
            label{
              color: #000000;
            };
    }&.disable-clear-button{
           pointer-events: none !important;
           cursor: not-allowed !important;
    }}
  }}}}

  .archive-table {
    td.view-action {
      opacity: 0;
      color: #eb590b;
      font-weight: 500;
    }
    tr:hover {
      td.view-action {
        opacity: 1;
      }      
    }
  }
  .side-list {
    margin-top: 20px;
    li {
      margin-bottom: 16px;
    }
  }

.question-msg-box {
  border-radius: 2px;
  background: var(--table-color-alternate-row-grey, #F5F5F5);
  padding: 10px;
  margin-top: 12px;
  svg {
    min-width: 12px;
    margin-right: 10px;
  }
  span {
    color: #464746;
    font-size: 14px;
    font-weight: 400;
  }
}

.archive_lock_tooltip {
  width: 217px;
  height: 140px;
  padding: 10px !important;
  font-size: 13px !important;
}

.archive-multi-group-line {
  .header {
    display: flex;
    margin-bottom: 24px;

    .group {
      padding: 10px 0px;
      width: 100%;
      color: lightgray;
    }

    .group-number {
      padding: 6px 0px;
      border-bottom: 2px solid transparent;
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      color: #7d7d7d;
      text-transform: uppercase;
      margin-right: 20px;
    }

    .group-number.active {
      border-bottom: 3px solid #fa5928;
      color: #fa5928;
    }
  }
}

.user-table  tr {
  position: relative;
  &:nth-last-child(2) {
    .dropdown-menu.show {      
      height: fit-content;
      top: -20px !important;
  }
    }
&:last-child {
.dropdown-menu.show {
  height: fit-content;
  top: -20px !important;
}
    
  }
  &:nth-last-child(2) {
    .dropdown-menu.show {      
      height: fit-content;
      top: -20px !important;
  }
    }
    &:first-child {
      .dropdown-menu.show {
        height: fit-content;
        top: 0 !important;
    }
  }
}