.content-container {
  padding: 10px 35px !important;
  margin-top: 98px !important;
}
.question_pane .title span {
  color: #eb590b;
  margin-right: 10px;
}
.top-title {
  margin-bottom: 10px;
}
.question_pane .title {
  color: #000000;
  font-size: 20px;
  font-weight: 500;
  padding-right: 26px;
}
.card_bg {
  text-align: left;
  padding: 10px;
  background: #fff;
  margin-bottom: 20px;
}
.card_bg .table_title {
  font-weight: 500;
  margin-bottom: 14px !important;
}
.status_card .item {
  margin-bottom: 10px;
}
.status_card .item:last-child {
  margin-bottom: 0;
}
.status_card .item .name {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: px;
  display: block;
}
.status_card .item .value {
  font-weight: 400;
  font-size: 14px;
  color: #2D2D2D;
  display: block;
}
.status_card .item .badge_sp {
  background: rgba(3, 135, 0, 0.2);
  padding: 3px 5px;
  font-size: 12px;
  color: #175C2C;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-block;
  font-family: "PwC Helvetica Neue";
  border-radius: 2px;
}

.action span {
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 4px;
}
.tabs_wrap a {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  margin-right: 20px;
  color: #7D7D7D !important;
  cursor: pointer;
}
.tabs_wrap a:last-child {
  margin-right: 0;
}
.tabs_wrap a.active {
  color: #eb590b !important;
  position: relative;
}
.tabs_wrap a.active::after {
  content: '';
  width: 100%;
  border-bottom: 2px solid #eb590b;
  position: absolute;
  bottom: -5px;
  left: 0;
}
.preview-table-scroller {
  max-height: unset !important;
}
.preview-a-table th {
  padding: 5px 0.625rem !important; 
  background: #DFE0DF;
  font-weight: 500;
  font-size: 14px;
}
.preview-a-table td {
  padding: 5px 0.625rem !important;
  font-size: 14px !important;
  line-height: normal !important;
  color: #000;
  vertical-align: middle;
}

 .stackholder-background{
    padding: 10px;
    background: #F2F2F2;
    border-radius: 1000px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 210px;
    height: 30px;
    background: #F2F2F2;
    border-radius: 1000px;
    flex: none;
    order: 0;
    flex-grow: 0;
    display: flex;
   flex-direction: row;
}

.stackholder-table-header{
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 210px;
  height: 25px;
  border-radius: 1000px;
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
 flex-direction: row;
}

.message-0-stakeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #BDBDBD;
  background: #fff;
  text-align: center;
  margin-top: 40px;
}
.message-0-stakeholder-wrap {
  width: 50%;
  margin: auto;
}
.custom-width-cell {
  width: 220px !important;
}


@media (max-width: 1400.98px) { 
  .preview-table.preview-a-table th {
    font-size: 12px;
  }
  .preview-table.preview-a-table td {
    font-size: 12px !important;
  }
  .message-0-stakeholder {
    font-size: 12px !important;
  }
 }