.container {
  width: 100%;
  background-color: #EEE;
}
.pwcsidebar-enter {
  transform: translateX(100%)
}

.pwcsidebar-enter.pwcsidebar-enter-active {
  transform: translateX(0);
  transition: all 250ms ease-in;
}

.pwcsidebar-leave {
  transform: translateX(0);
}

.pwcsidebar-leave.pwcsidebar-leave-active {
  transform: translateX(100%);
  transition: all 250ms ease-in;
}